
import { defineComponent } from 'vue';
import { PrintAPI } from '@/services/PrintAPIClient';
import { mapActions } from 'vuex';
import { Paper } from '@/services/jobs/types';

import PrimaryButton from '@/components/buttons/PrimaryButton.vue';
import AccentButton from '@/components/buttons/AccentButton.vue';
import TertiaryButton from '@/components/buttons/TertiaryButton.vue';
import Input from '@/components/forms/Input.vue';

export default defineComponent({
  name: 'AddPaper',
  components: {
    PrimaryButton,
    AccentButton,
    TertiaryButton,
    Input
  },
  data() {
    return {
      paper: {} as Paper
    };
  },
  methods: {
    ...mapActions(['ShowNotification']),
    async submitForm(mode: string) {
      const {
        paperSize,
        paperName,
        cost10,
        cost11,
        cost40,
        cost44,
        retail10,
        retail11,
        retail40,
        retail44,
        cutFee
      } = this.paper;
      if (
        !paperSize ||
        !paperName ||
        !cost10 ||
        !cost11 ||
        !cost40 ||
        !cost44 ||
        !retail10 ||
        !retail11 ||
        !retail40 ||
        !retail44 ||
        !cutFee
      ) {
        this.ShowNotification({
          type: 'warning',
          message: 'Please fill in required fields'
        });
        return;
      }
      try {
        await PrintAPI.post('/createPaper', this.paper);
        this.ShowNotification({
          type: 'success',
          message: 'Paper has been created and saved successfully!'
        });
        if (mode === 'save') this.$router.go(-1);
        if (mode === 'add') this.$router.go(0);
      } catch (error) {
        this.ShowNotification({
          type: 'error',
          message: 'Error creating paper'
        });
        return error;
      }
    }
  }
});
