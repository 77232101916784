
import { defineComponent } from 'vue';
import { mapState } from 'vuex';
import { RootState } from '@/store/types';

import AccountControls from '@/global/HeaderAccountControls.vue';

export default defineComponent({
  name: 'Header',
  components: {
    AccountControls
  },
  data() {
    return {
      accountControlsOpen: false,
      searchInput: ''
    };
  },
  computed: mapState<RootState>({
    userFirstName: (state: RootState) => state.user.firstName
  }),
  mounted() {
    document.addEventListener('click', this.closeAccountControls);
  },
  beforeUnmount() {
    document.removeEventListener('click', this.closeAccountControls);
  },
  methods: {
    toggleAccountControls() {
      this.accountControlsOpen = !this.accountControlsOpen;
    },
    closeAccountControls(e: Event) {
      if (!this.$el.contains(e.target)) {
        this.accountControlsOpen = false;
      }
    }
  }
});
