import { PrintAPI } from '../PrintAPIClient';
import { ConvertDate } from '../utils-times';
import { User, UsersReturn, DetailPanelDataUser } from './types';

export const getUsers = async (): Promise<User[]> => {
  const response = await PrintAPI.get('/users');

  return response.data.filter((item: User) => item.deletedAt === null);
};

export const fetchTableUsers = async (): Promise<UsersReturn> => {
  try {
    const response = await PrintAPI.get('/users');

    return {
      users: response.data,
      userProperties: [
        'accountName',
        'username',
        'firstName',
        'lastName',
        'email',
        'role'
      ],
      userTableHeadProperties: [
        'Account Name',
        'Username',
        'First Name',
        'Last Name',
        'Email',
        'Role'
      ]
    };
  } catch (error: any) {
    return error;
  }
};

export const getDetailPanelUser = async (
  id: number
): Promise<DetailPanelDataUser> => {
  try {
    const { data } = await PrintAPI.get(`/getUser/${id}`);

    if (data.hourly) {
      data.hourly = 'Yes';
    } else {
      data.hourly = 'No';
    }

    if (data.lastLogin) {
      const convertedDate = ConvertDate(data.lastLogin);
      data.lastLogin = convertedDate;
    } else {
      data.lastLogin = 'No login recorded';
    }

    if (data.deletedAt) {
      const convertedDate = ConvertDate(data.deletedAt);
      data.deletedAt = convertedDate;
    } else {
      data.deletedAt = 'N/A';
    }

    const detailPanelData: DetailPanelDataUser = {
      resourceId: id,
      headerData: {
        key1: 'User',
        key2: `${data.firstName} ${data.lastName}`
      },
      bodyData: {
        'First Name': data.firstName,
        'Last Name': data.lastName,
        Email: data.email,
        Username: data.username,
        Role: data.role,
        Hourly: data.hourly,
        'Last Login': data.lastLogin,
        Archived: data.deletedAt
      }
    };

    return detailPanelData;
  } catch (error: any) {
    return error;
  }
};
