
import { defineComponent } from 'vue';
import Pagination from 'v-pagination-3';
import PaginationTemplate from '@/global/Pagination.vue';
type TableObject = { [key: string]: any };

export default defineComponent({
  name: 'Table',
  components: {
    Pagination
  },
  props: {
    datasets: {
      type: Array,
      default() {
        return [];
      }
    },
    properties: {
      type: Array,
      default() {
        return [];
      }
    },
    tableHeadProperties: {
      type: Array,
      default() {
        return [];
      }
    }
  },
  emits: ['openTableDetail'],
  data() {
    return {
      sortField: '',
      tableData: this.datasets,
      page: 1,
      tempData: [] as TableObject[],
      pageItems: [] as TableObject[],
      len: this.datasets.length,
      acending: true,
      prevField: ''
    };
  },
  computed: {
    sortedArray(): TableObject[] {
      let sorted = JSON.parse(JSON.stringify(this.tableData));
      if (this.sortField === '') {
        return sorted;
      }
      const property = this.sortField;
      if (property != '' && property) {
        if (this.acending) {
          if (typeof sorted[0][property] === 'string') {
            if (property.toLowerCase().includes('date')) {
              sorted = sorted.sort((a: TableObject, b: TableObject) => {
                if (a[property] === 'Unknown') {
                  return 1;
                } else if (b[property] === 'Unknown') {
                  return -1;
                } else if (a[property] === b[property]) {
                  return 0;
                }
                return (
                  new Date(a[property]).getTime() -
                  new Date(b[property]).getTime()
                );
              });
            } else if (sorted[0][property].includes('$')) {
              sorted = sorted.sort((a: TableObject, b: TableObject) => {
                return (
                  Number(a[property].replace('$', '')) -
                  Number(b[property].replace('$', ''))
                );
              });
            } else {
              sorted = sorted.sort((a: TableObject, b: TableObject) => {
                let fa = a[property].toLowerCase(),
                  fb = b[property].toLowerCase();
                if (fa < fb) {
                  return -1;
                }
                if (fa > fb) {
                  return 1;
                }
                return 0;
              });
            }
          } else {
            sorted = sorted.sort((a: TableObject, b: TableObject) => {
              return a[property] - b[property];
            });
          }
        } else {
          if (typeof sorted[0][property] === 'string') {
            if (property.toLowerCase().includes('date')) {
              sorted = sorted.sort((a: TableObject, b: TableObject) => {
                if (a[property] === 'Unknown') {
                  return 1;
                } else if (b[property] === 'Unknown') {
                  return -1;
                } else if (a[property] === b[property]) {
                  return 0;
                }
                return (
                  new Date(b[property]).getTime() -
                  new Date(a[property]).getTime()
                );
              });
            } else if (sorted[0][property].includes('$')) {
              sorted = sorted.sort((a: TableObject, b: TableObject) => {
                return (
                  Number(b[property].replace('$', '')) -
                  Number(a[property].replace('$', ''))
                );
              });
            } else {
              sorted = sorted.sort((a: TableObject, b: TableObject) => {
                let fa = a[property].toLowerCase(),
                  fb = b[property].toLowerCase();
                if (fb < fa) {
                  return -1;
                }
                if (fb > fa) {
                  return 1;
                }
                return 0;
              });
            }
          } else {
            sorted = sorted.sort((a: TableObject, b: TableObject) => {
              return b[property] - a[property];
            });
          }
        }
      }
      let res = JSON.parse(JSON.stringify(sorted));
      res = Object.values(res);
      return res;
    }
  },
  watch: {
    datasets: function (newprop) {
      this.tableData = newprop;
      this.len = newprop.length;
      this.onChangePage();
    }
  },
  created() {
    this.onChangePage();
    // this.acending = !this.acending;
  },
  methods: {
    onClick(id: number) {
      if (this.$route.path === '/view-hours') {
        this.$router.push(`/edit-hours/${id}`);
      } else {
        this.$emit('openTableDetail', id);
      }
    },
    toggleSort(field: string) {
      this.sortField = field;
      if (field === this.prevField) {
        this.acending = !this.acending;
      } else {
        this.acending = true;
      }
      this.pageItems = this.sortedArray.slice(
        this.page * 12 - 12,
        this.page * 12
      );
      this.prevField = field;
    },
    async onChangePage() {
      this.pageItems = this.sortedArray.slice(
        this.page * 12 - 12,
        this.page * 12
      ); // get the slice of the jobs in current page
      console.log(this.pageItems);
    }
  },
  options: {
    template: PaginationTemplate
  }
});
