
import { defineComponent } from 'vue';
import { mapActions, mapGetters } from 'vuex';
import { fetchTableJobs, getDetailPanelJob } from '@/services/jobs/jobs-data';
import { PanelBtn } from '@/store/types';
import { Job, DetailPanelData } from '@/services/jobs/types';
import { PrintAPI } from '@/services/PrintAPIClient';
import { exportCSVFile } from '@/services/utils-csv-export';

import SearchBar from '@/components/searches/SearchBar.vue';
import PrimaryButton from '@/components/buttons/PrimaryButton.vue';
import SecondaryButton from '@/components/buttons/SecondaryButton.vue';
import Table from '@/components/tables/Table.vue';
import TableDetail from '@/components/tables/TableDetail.vue';
import Loading from 'vue-loading-overlay';

export default defineComponent({
  name: 'JobHistory',
  components: {
    SearchBar,
    PrimaryButton,
    SecondaryButton,
    Table,
    TableDetail,
    Loading
  },
  data() {
    return {
      isLoading: false,
      jobs: [] as Job[],
      jobProperties: [] as string[],
      jobTableHeadProperties: [] as string[],
      filename: '',
      timer: null as number | null,
      debouncedSearchInput: '',
      billed: false,
      active: true,
      panelData: {},
      panelBtns: [] as PanelBtn[]
    };
  },
  computed: {
    ...mapGetters(['GetDetailPanelIsOpen']),
    filterJobs(): Job[] {
      let returnJobs = this.jobs;
      let filteredJobs = this.jobs;
      if (this.billed) {
        filteredJobs = filteredJobs.filter((job: Job) => job.complete === null);
      }

      if (this.active) {
        filteredJobs = filteredJobs.filter(
          (job: Job) => job.deletedAt === null
        );
      } else {
        filteredJobs = filteredJobs.filter((job: Job) => job.deletedAt != null);
      }

      returnJobs = filteredJobs;

      if (this.debouncedSearchInput != '' && this.debouncedSearchInput) {
        returnJobs = filteredJobs.filter((item: Job) => {
          return this.debouncedSearchInput
            .toLowerCase()
            .split(' ')
            .every((v) => JSON.stringify(item).toLowerCase().includes(v));
        });
      }

      return returnJobs;
    },
    searchInput: {
      get(): string {
        return this.debouncedSearchInput;
      },
      set(val: string) {
        if (this.timer) clearTimeout(this.timer);
        this.timer = setTimeout(() => {
          this.debouncedSearchInput = val;
        }, 500);
      }
    }
  },
  created() {
    this.fetchJobs();
  },

  methods: {
    ...mapActions(['OpenDetailPanel', 'CloseDetailPanel', 'ShowNotification']),
    async fetchJobs() {
      try {
        this.isLoading = true;
        const response = await fetchTableJobs();
        this.jobs = response.jobs;
        this.jobProperties = response.jobProperties;
        this.jobTableHeadProperties = response.jobTableHeadProperties;
        this.isLoading = false;
      } catch (error) {
        return error;
      }
    },
    async openTableDetail(id: number) {
      const response = await getDetailPanelJob(id);

      this.panelData = response;

      const tertiaryBtnLabel =
        response.bodyData.Billed === 'Not Billed'
          ? 'Mark Billed'
          : 'Unmark Billed';

      this.panelBtns = [
        { type: 'tertiary', label: tertiaryBtnLabel },
        { type: 'primary', label: 'Edit' }
      ];

      this.OpenDetailPanel();
    },
    handlePrimaryBtn(id: number) {
      this.$router.push(`/edit-job/${id}`);
      this.CloseDetailPanel();
    },
    handleTertiaryBtn(resourceId: number, panelData: DetailPanelData) {
      panelData.bodyData.Billed === 'Not Billed'
        ? this.markJobBilled(resourceId)
        : this.unmarkJobBilled(resourceId);
    },
    async markJobBilled(id: number) {
      try {
        await PrintAPI.put(`/editJob/${id}`, {
          complete: new Date().toISOString()
        });
        this.ShowNotification({
          type: 'success',
          message: 'Job marked as BILLED successfully!'
        });
        this.fetchJobs();
      } catch (error) {
        this.ShowNotification({
          type: 'error',
          message: 'Error marking job billed'
        });
        return error;
      }
    },
    async unmarkJobBilled(id: number) {
      if (
        window.confirm('Are you sure you want to mark this job as NOT billed?')
      ) {
        try {
          await PrintAPI.put(`/editJob/${id}`, {
            complete: null
          });
          this.ShowNotification({
            type: 'success',
            message: 'Job marked NOT BILLED successfully!'
          });
          this.fetchJobs();
        } catch (error) {
          this.ShowNotification({
            type: 'error',
            message: 'Error unmarking job billed'
          });
          return error;
        }
      }
    },
    download() {
      const headers = {
        id: 'Job #'.replace(/,/g, ''),
        // company_name: 'Company Name',
        job_description: 'Job Description',
        date_printed: 'Date Printed',
        quantity: 'Quantity',
        // paper: 'Paper',
        // finise_size: 'finise_size',
        notes: 'notes',
        print: 'print',
        postage: 'postage',
        cost: 'Cost',
        complete: 'Complete',
        x_x: 'x_x'
      };

      // const itemsNotFormatted = this.jobs;
      const itemsNotFormatted = this.jobs;

      const itemsFormatted = [] as any[];

      // format the data
      itemsNotFormatted.forEach((item) => {
        itemsFormatted.push({
          id: item.id,
          // company_name: item.companyName,
          job_description: item.jobDescription
            .replace(/,/g, '')
            .replace(/\n/g, ''),
          date_printed: item.datePrinted,
          quantity: item.quantity,
          // paper: item.paper,
          notes: item.notes
            ? item.notes.replace(/,/g, '').replace(/\n/g, '')
            : '',
          print: item.print,
          postage: item.postage,
          cost: item.cost,
          complete: item.complete,
          x_x: item.x_x
        });
      });

      const fileTitle = 'job-data'; // or 'my-unique-title'
      // call the exportCSVFile() function to process the JSON and trigger the download
      exportCSVFile(headers, itemsFormatted, fileTitle);
    }
  }
});
