import { toDisplayString as _toDisplayString, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createBlock("div", null, [
    _createVNode("label", {
      for: _ctx.name,
      class: "font-bold text-grey-darker block my-4"
    }, _toDisplayString(_ctx.label), 9, ["for"]),
    _createVNode("textarea", {
      class: "\n        block\n        w-full\n        bg-white\n        border border-grey-light\n        px-2\n        py-2\n        rounded\n        shadow\n      ",
      placeholder: _ctx.placeholderText,
      name: _ctx.name,
      required: _ctx.required,
      value: _ctx.modelValue,
      onInput: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.onInput && _ctx.onInput(...args)))
    }, null, 40, ["placeholder", "name", "required", "value"])
  ]))
}