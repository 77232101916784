
import { defineComponent } from 'vue';
import { mapActions } from 'vuex';
import { PrintAPI } from '@/services/PrintAPIClient';
import {
  Job,
  Company,
  Department,
  Paper,
  JobCalculatorInput
} from '@/services/jobs/types';
import { User } from '@/services/users/types';
import {
  getJob,
  getCompanies,
  getDepartments,
  getPaper
} from '@/services/jobs/job-form-data';
import { getUsers } from '@/services/users/users-data';

import Input from '@/components/forms/Input.vue';
import InputCurrency from '@/components/forms/InputCurrency.vue';
import Textarea from '@/components/forms/Textarea.vue';
import Select from '@/components/forms/Select.vue';
import Checkbox from '@/components/forms/Checkbox.vue';
import PrimaryButton from '@/components/buttons/PrimaryButton.vue';
import TertiaryButton from '@/components/buttons/TertiaryButton.vue';
import AccentButton from '@/components/buttons/AccentButton.vue';

export default defineComponent({
  name: 'JobForm',
  components: {
    Input,
    InputCurrency,
    Textarea,
    Select,
    Checkbox,
    PrimaryButton,
    TertiaryButton,
    AccentButton
  },
  data() {
    return {
      companies: [] as Company[],
      departments: [] as Department[],
      paper: [] as Paper[],
      paperSizeOptions: [] as string[],
      finishedSize: '',
      xxOptions: ['1/0', '1/1', '4/0', '4/4'] as string[],
      disableXX: false,
      users: [] as User[],
      job: {
        up: 1,
        datePrinted: new Date().toISOString().substr(0, 10),
        completedById: this.$store.state.user.id
      } as Job,
      showRequiredClass: 'text-red-400',
      showRequiredForCalc: false
    };
  },
  computed: {
    filteredPaper: function (): Paper[] {
      return this.paper.filter(
        (paper) => paper.paperSize === this.finishedSize
      );
    }
  },
  props: {
    mode: { type: String, default: 'add-new' }, //two options only: 'add-new' or 'edit'
    handleFormSubmit: { type: Function, default: () => false },
    disableButtons: { type: Boolean, default: false },
    showRequired: { type: Boolean, default: false }
  },
  watch: {
    finishedSize: function (val) {
      if (val === 'Business Cards') {
        this.job.quantity = 500;
        this.xxOptions = ['4/0', '4/4'];
      }
      if (
        val === 'Up Logs' ||
        val === 'Buyers Guide' ||
        val === 'YAG Window Sticker'
      ) {
        this.job.x_x = '1/0';
        this.disableXX = true;
      }
      if (val === '4x6 Thank you card' || val === '5x7 Thank you card folded') {
        this.job.quantity = 250;
        this.job.x_x = '1/0';
      }
    },
    'job.companyId': function () {
      if (this.$props.mode === 'add-new') {
        this.handleUpdate();
      }
    },
    'job.quantity': function () {
      if (this.$props.mode === 'add-new') {
        this.handleUpdate();
      }
    },
    'job.x_x': function () {
      if (this.$props.mode === 'add-new') {
        this.handleUpdate();
      }
    },
    'job.paperId': function () {
      if (this.$props.mode === 'add-new') {
        this.handleUpdate();
      }
    },
    'job.cuts': function () {
      if (this.$props.mode === 'add-new') {
        this.handleUpdate();
      }
    },
    'job.complete': function () {
      if (this.$props.mode === 'edit' && this.job.complete) {
        this.job.complete = new Date().toISOString();
      }
    }
  },
  created() {
    this.fetchData();
  },
  methods: {
    ...mapActions(['ShowNotification']),
    async fetchData() {
      this.companies = await getCompanies();
      this.departments = await getDepartments();
      const paper = await getPaper();
      this.paper = paper.paper;
      this.paperSizeOptions = paper.paperSizeOptions;
      this.users = await getUsers();
      if (this.$props.mode === 'edit') {
        const job = await getJob(Number(this.$route.params.id));
        this.job = job.job;
        this.finishedSize = job.finishedSize;
      }
    },
    handleUpdate() {
      if (this.showRequiredForCalc) this.showRequiredForCalc = false;
      const { companyId, quantity, x_x, paperId, cuts } = this.job;
      if (companyId && quantity && x_x && paperId) {
        this.calculate({ companyId, quantity, x_x, paperId, cuts });
      } else {
        if (this.$props.mode === 'edit') {
          this.showRequiredForCalc = true;
          this.ShowNotification({
            type: 'warning',
            message: 'Please fill in required fields'
          });
        }
      }
    },
    async calculate(data: JobCalculatorInput) {
      const response = await PrintAPI.post('/calculate', data);

      const tempCost = response.data.cost.toFixed(2);
      const tempRetail = response.data.retail.toFixed(2);

      this.job.cost = Number(tempCost);
      this.job.print = Number(tempRetail);
    },
    submitForm(mode: string) {
      const jobData = this.job;

      this.$props.handleFormSubmit(jobData, mode);
    }
  }
});
