import { renderSlot as _renderSlot, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createBlock("button", {
    class: [
      _ctx.$props.btnWidth,
      _ctx.$props.btnHeight,
      'bg-white border-bright_blue border-2 text-bright_blue font-bold rounded-2xl p-2'
    ],
    type: _ctx.type,
    onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.handleClick && _ctx.handleClick(...args))),
    disabled: _ctx.disabled
  }, [
    _renderSlot(_ctx.$slots, "default")
  ], 10, ["type", "disabled"]))
}