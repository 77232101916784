// handle date conversions
export const ConvertDate = (date: string): string => {
  const dateString = new Date(date);
  const dateArray = dateString.toString().split(' ', 4);
  const newDate = dateArray.join(' ');

  return newDate;
};

// handle datetime conversions
export const ConvertDateTime = (date: string): string => {
  const dateString = new Date(date);
  const dateArray = dateString.toString().split(' ', 5);
  const time = dateArray[dateArray.length - 1];
  const timeArray = time.split(':', 2);
  let isPM = false;

  if (Number(timeArray[0]) > 12) {
    const newHour = Number(timeArray[0]) - 12;
    const hourString = newHour.toString();
    timeArray[0] = hourString;
    isPM = true;
  } else if (Number(timeArray[0]) === 12) {
    isPM = true;
  } else if (Number(timeArray[0]) === 0) {
    timeArray[0] = '12';
  }

  const newTime = timeArray.join(':');
  dateArray[dateArray.length - 1] = newTime;

  if (isPM === true) {
    dateArray.push('PM');
  } else {
    dateArray.push('AM');
  }

  const newDate = dateArray.join(' ');

  return newDate;
};

export const ComputeTime = (dateString: string): string => {
  // if (dateString === null) {
  //   return null;
  // }
  const newDate = new Date(dateString).toLocaleString();
  const dateArray = newDate.split(' ');
  const time = dateArray[1];
  const timeArray = time.split(':');
  const hours = timeArray[0];
  let trueHours = '';

  if (dateArray[2] === 'PM' && Number(hours) < 12) {
    trueHours = (Number(hours) + 12).toString();
  } else if (Number(hours) === 12 && dateArray[2] === 'AM') {
    trueHours = '0';
  } else {
    trueHours = hours;
  }

  if (Number(trueHours) < 10) {
    trueHours = '0' + trueHours;
  }

  const minutes = timeArray[1];
  const newTime = trueHours + ':' + minutes;
  return newTime;
};

export const ConvertTime = (time: string): string => {
  // if (time === null) {
  //   return null;
  // }

  const timeArray = time.split(':');
  let suffix = 'AM';

  if (Number(timeArray[0]) >= 12) {
    suffix = 'PM';
  }

  if (Number(timeArray[0]) > 12) {
    const newHour = Number(timeArray[0]) - 12;
    timeArray[0] = newHour.toString();
  } else if (Number(timeArray[0]) === 0) {
    timeArray[0] = '12';
  } else {
    const newHour = Number(timeArray[0]);
    timeArray[0] = newHour.toString();
  }

  const newTime = timeArray[0] + ':' + timeArray[1] + ' ' + suffix;
  return newTime;
};
