
import { defineComponent } from 'vue';
import { mapActions } from 'vuex';
import { PrintAPI } from '@/services/PrintAPIClient';

import Input from '@/components/forms/Input.vue';
import Checkbox from '@/components/forms/Checkbox.vue';
import AccentButton from '@/components/buttons/AccentButton.vue';
import PrimaryButton from '@/components/buttons/PrimaryButton.vue';
import TertiaryButton from '@/components/buttons/TertiaryButton.vue';

export default defineComponent({
  name: 'AddCompany',
  components: {
    Input,
    Checkbox,
    AccentButton,
    PrimaryButton,
    TertiaryButton
  },
  data() {
    return {
      company: {
        name: '',
        isYag: false,
        showOnOrderForm: false
      }
    };
  },
  methods: {
    ...mapActions(['ShowNotification']),
    async submitForm(mode: string) {
      if (!this.company.name) {
        this.ShowNotification({
          type: 'warning',
          message: 'Please fill in required fields'
        });

        return;
      }
      try {
        await PrintAPI.post('/createCompany', this.company);
        this.ShowNotification({
          type: 'success',
          message: 'Company created successfully!'
        });
        if (mode === 'save') this.$router.go(-1);
        if (mode === 'add') this.$router.go(0);
      } catch (error) {
        this.ShowNotification({
          type: 'error',
          message: 'Something went wrong'
        });
        return error;
      }
    }
  }
});
