import { Module, GetterTree, ActionTree, MutationTree } from 'vuex';
import { RootState } from '../types';

interface notificationState {
  showNotification: boolean;
  notification: notification;
}

interface notification {
  type: string;
  message: string;
}

const initializeState = (): notificationState => {
  return {
    showNotification: false,
    notification: {
      type: '',
      message: ''
    }
  };
};

const state: notificationState = initializeState();

const getters: GetterTree<notificationState, RootState> = {
  GetShowNotification: (state) => state.showNotification,
  GetNotificationType: (state) => state.notification.type,
  GetNotificationMessage: (state) => state.notification.message
};

const actions: ActionTree<notificationState, RootState> = {
  ShowNotification: ({ commit }, notification: notification) => {
    setTimeout(() => {
      commit('SetClearNotification');
    }, 3000);

    commit('SetShowNotification', notification);
  },
  ClearNotification: ({ commit }) => {
    commit('SetClearNotification');
  }
};

const mutations: MutationTree<notificationState> = {
  SetShowNotification: (state, notification) => {
    state.notification.type = notification.type;
    state.notification.message = notification.message;
    state.showNotification = true;
  },
  SetClearNotification: (state) => {
    Object.assign(state, initializeState());
  }
};

const notifications: Module<notificationState, RootState> = {
  state,
  getters,
  actions,
  mutations
};

export default notifications;
