
import { defineComponent } from 'vue';
import { mapActions } from 'vuex';

export default defineComponent({
  name: 'PagePanel',
  methods: {
    ...mapActions(['ShowNotification'])
  }
});
