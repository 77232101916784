import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import './assets/tailwind.css';

/* Font Awesome icons */
// import utility functions for importing FA icons into project
import { library } from '@fortawesome/fontawesome-svg-core';
// import specific FA icons from FA icon library
import {
  faHome,
  faList,
  faDollarSign,
  faCalendarAlt,
  faUsers,
  faChevronDown,
  faChevronUp,
  faChevronLeft,
  faChevronRight,
  faTimes,
  faPlusCircle,
  faDownload,
  faBell,
  faQuestionCircle,
  faTrash,
  faSort,
  faEdit
} from '@fortawesome/free-solid-svg-icons';
// import the component to display FA icons
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

// use library utility function to add FA icons to project
library.add(
  faHome,
  faList,
  faDollarSign,
  faCalendarAlt,
  faUsers,
  faChevronDown,
  faChevronUp,
  faChevronLeft,
  faChevronRight,
  faTimes,
  faPlusCircle,
  faDownload,
  faBell,
  faQuestionCircle,
  faTrash,
  faSort,
  faEdit
);

createApp(App)
  .use(store)
  .use(router)
  .component('font-awesome-icon', FontAwesomeIcon) // add the FA component to App and name it 'font-awesome-icon'
  .mount('#app');
