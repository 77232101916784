
import { defineComponent } from 'vue';
import { PrintAPI } from '@/services/PrintAPIClient';
import { Paper } from '@/services/jobs/types';
import { mapActions } from 'vuex';

import PrimaryButton from '@/components/buttons/PrimaryButton.vue';
import Loading from 'vue-loading-overlay';
type TableLineItem = { [key: string]: any };

export default defineComponent({
  name: 'EditPaperTable',
  components: {
    PrimaryButton,
    Loading
  },
  data() {
    return {
      editsMade: false,
      isLoading: false,
      tableData: [] as TableLineItem,
      updatedTableData: [] as TableLineItem[],
      paperProperties: [
        'paperSize',
        'paperName',
        'cost10',
        'cost11',
        'cost40',
        'cost44',
        'retail10',
        'retail11',
        'retail40',
        'retail44',
        'cutFee'
      ],
      paperTableHeadProperties: [
        'Paper Size',
        'Paper Name',
        'P&M Cost 1/0',
        'P&M Cost 1/1',
        'P&M Cost 4/0',
        'P&M Cost 4/4',
        'Customer Cost 1/0',
        'Customer Cost 1/1',
        'Customer Cost 4/0',
        'Customer Cost 4/4',
        'Cut Fee',
        'Actions'
      ],
      newLineItem: {}
    };
  },
  created() {
    this.fetchPapers();
  },
  beforeRouteLeave(to, from) {
    if (this.editsMade) {
      const answer = window.confirm(
        'Do you really want to leave? You have unsaved changes! Click "Cancel" to save your changes before leaving this page.'
      );
      if (!answer) return false;
    }
  },
  methods: {
    ...mapActions(['ShowNotification']),
    async fetchPapers() {
      try {
        this.isLoading = true;
        const response = await PrintAPI.get('/papers');
        this.tableData = response.data.filter(
          (item: Paper) => item.deletedAt === null
        );
        this.isLoading = false;
      } catch (error) {
        return error;
      }
    },
    tableItemUpdate(value: string, id: number, property: string) {
      this.editsMade = true;

      const match = this.updatedTableData.find((e: any) => e.id === id);
      if (!match) {
        const obj: any = { id };
        obj[property] = value;
        this.updatedTableData.push(obj);
      }

      const index = this.updatedTableData.map((i: any) => i.id).indexOf(id);
      this.updatedTableData[index][property] = value;
    },
    async saveEdits() {
      if (window.confirm('Are you ready to make your updates live?')) {
        try {
          await PrintAPI.put(`/updateBulkPaper`, this.updatedTableData);
          this.ShowNotification({
            type: 'success',
            message: 'All paper successfully updated!'
          });
          this.editsMade = false;
          this.fetchPapers();
        } catch (e) {
          this.ShowNotification({
            type: 'error',
            message: 'Error updating paper.'
          });
          return e;
        }
      }
    },
    async deleteTableLineItem(id: number) {
      if (window.confirm('Are you sure you want to archive this paper?')) {
        try {
          await PrintAPI.delete(`/deletePaper/${id}`);
          this.ShowNotification({
            type: 'success',
            message: 'Paper successfully archived!'
          });
          this.fetchPapers();
        } catch (e) {
          this.ShowNotification({
            type: 'error',
            message: 'Error archiving paper.'
          });
          return e;
        }
      }
    }
  }
});
