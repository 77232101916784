import { PrintAPI } from '../PrintAPIClient';
import { timeObject, TimersReturn } from './types';
import { ConvertDate, ComputeTime, ConvertTime } from '../utils-times';

//---- Timeclock System Helpers -------
export const findTodayTimes = (timesArr: timeObject[]): timeObject[] => {
  const todayTimes = timesArr.filter((time) => {
    const today = new Date().toDateString();
    const timeIn = new Date(time.timeIn).toDateString();

    if (today === timeIn) {
      return time;
    }
  });

  return todayTimes;
};

export const findCurrentInTime = (timesArr: timeObject[]): timeObject[] => {
  const inTime = timesArr.filter((time) => time.timeOut === null);

  return inTime;
};

export const findAccumulatedTime = (timesArr: timeObject[]): number => {
  let accumulatedTime = 0;

  timesArr.forEach((time) => {
    const totalTime = Number(time.totalTime);
    accumulatedTime = accumulatedTime + totalTime;
  });
  if (accumulatedTime === 0) {
    return 0;
  }
  const timeInMilliseconds = accumulatedTime * 1000 * 60 * 60;
  return timeInMilliseconds;
};

export const findCompleteHours = (timesArr: timeObject[]): timeObject[] => {
  const completedHours = timesArr.filter((time) => time.timeOut !== null);

  return completedHours;
};

export const findIncompleteHours = (timesArr: timeObject[]): timeObject[] => {
  const incompleteHours = timesArr.filter((time) => time.timeOut === null);

  return incompleteHours;
};
//---- End Timeclock System Helpers -------

//------------ Timer Table Helpers -----------
export const fetchTableTimers = async (): Promise<TimersReturn> => {
  try {
    const response = await PrintAPI.get('/timers/?take=1500');
    const timers = response.data;

    return formatTimersTable(timers);
  } catch (error: any) {
    return error;
  }
};

export const formatTimersTable = (data: timeObject[]): TimersReturn => {
  const timersArray = data.map((timer: timeObject) => {
    const createdBy = timer.createdBy
      ? `${timer.createdBy.firstName} ${timer.createdBy.lastName}`
      : '';

    const computeTimeIn = ComputeTime(timer.timeIn);
    const convertTimeIn = ConvertTime(computeTimeIn);
    let convertTimeOut;
    let hours = 0;

    if (timer.timeOut) {
      const computeTimeOut = ComputeTime(timer.timeOut);
      convertTimeOut = ConvertTime(computeTimeOut);

      const timeIn = new Date(timer.timeIn);
      const timeOut = new Date(timer.timeOut);
      const totalTime = timeOut.getTime() - timeIn.getTime();
      const calcHours = totalTime / (1000 * 60 * 60);
      const fixedHours = calcHours.toFixed(2);
      hours += Number(fixedHours);
    } else {
      convertTimeOut = '';
    }

    const approvedBy = timer.approvedBy
      ? `${timer.approvedBy.firstName} ${timer.approvedBy.lastName}`
      : '';

    let convertApprovedOn;
    if (timer.approvedOn) {
      convertApprovedOn = ConvertDate(timer.approvedOn);
    }

    const timerInfo = {
      createdByName: createdBy,
      date: ConvertDate(timer.timeIn),
      timeIn: convertTimeIn,
      noteIn: timer.noteIn
        ? timer.noteIn.replace(/,/g, '').replace(/\n/g, '')
        : '',
      timeOut: convertTimeOut,
      noteOut: timer.noteOut
        ? timer.noteOut.replace(/,/g, '').replace(/\n/g, '')
        : '',
      totalTime: hours,
      isVacation: timer.isVacation,
      isHoliday: timer.isHoliday,
      approvedOn: convertApprovedOn,
      approvedByName: approvedBy
    };

    return timerInfo;
  });
  return {
    timers: timersArray,
    timerProperties: [
      'createdByName',
      'date',
      'timeIn',
      'noteIn',
      'timeOut',
      'noteOut',
      'totalTime',
      'isVacation',
      'isHoliday',
      'approvedOn',
      'approvedBy'
    ],
    timerTableHeadProperties: [
      'Created By',
      'Date',
      'Time In',
      'Note In',
      'Time Out',
      'Note Out',
      'Total Time',
      'Is Vacation',
      'Is Holiday',
      'Approved On',
      'Approved By'
    ]
  };
};
//------------ End Timer Table Helpers -----------
