
import { defineComponent } from 'vue';
import { mapActions } from 'vuex';
import { PrintAPI } from '@/services/PrintAPIClient';
import { Department } from '@/services/jobs/types';

import PrimaryButton from '@/components/buttons/PrimaryButton.vue';
import Loading from 'vue-loading-overlay';

export default defineComponent({
  name: 'ManageDepartment',
  components: {
    PrimaryButton,
    Loading
  },
  data() {
    return {
      isLoading: false,
      departments: [] as Department[],
      departmentProperties: ['name'],
      departmentTableHeadProperties: ['Name'],
      active: true,
      updatedDepartment: {}
    };
  },
  computed: {
    filterDepartments(): Department[] {
      let returnDepartments = this.departments;
      let filteredDepartments = this.departments;

      if (this.active) {
        filteredDepartments = filteredDepartments.filter(
          (department: Department) => department.deletedAt === null
        );
      } else {
        filteredDepartments = filteredDepartments.filter(
          (department: Department) => department.deletedAt != null
        );
      }

      returnDepartments = filteredDepartments;

      return returnDepartments;
    }
  },
  created() {
    this.fetchDepartments();
  },
  methods: {
    ...mapActions(['ShowNotification']),
    async fetchDepartments() {
      try {
        const response = await PrintAPI.get('/departments');
        this.departments = response.data;
      } catch (e) {
        return e;
      }
    },
    async deleteDepartment(id: number) {
      if (window.confirm('Are you sure you want to archive this department?')) {
        try {
          await PrintAPI.delete(`/deleteDepartment/${id}`);
          this.ShowNotification({
            type: 'success',
            message: 'Department archived successfully!'
          });
          this.fetchDepartments();
        } catch (error) {
          this.ShowNotification({
            type: 'error',
            message: 'Error archiving department'
          });
          return error;
        }
      }
    }
  }
});
