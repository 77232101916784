
import { defineComponent } from 'vue';
export default defineComponent({
  name: 'PrimaryButton',
  props: {
    handleClick: { type: Function, default: () => false },
    type: { type: String, default: 'button' },
    btnWidth: { type: String, default: 'w-1/5' },
    btnHeight: { type: String, default: '' },
    disabled: { type: Boolean, default: false }
  }
});
