
import { defineComponent } from 'vue';
import { mapActions } from 'vuex';
import { PrintAPI } from '@/services/PrintAPIClient';
import { User } from '@/services/users/types';

import Input from '@/components/forms/Input.vue';
import AccentButton from '@/components/buttons/AccentButton.vue';
import PrimaryButton from '@/components/buttons/PrimaryButton.vue';

interface timer {
  id: number;
  date: string;
  timeIn: string;
  timeOut: string | null;
  totalTime?: number;
  noteIn: string | null;
  noteOut: string | null;
  userId?: number;
}

export default defineComponent({
  name: 'EditHours',
  components: {
    Input,
    AccentButton,
    PrimaryButton
  },
  data() {
    return {
      startDate: '',
      endDate: '',
      user: {} as User,
      allTimers: [] as timer[],
      completeTimers: [] as timer[],
      incompleteTimers: [] as timer[],
      success: false,
      edit: false
    };
  },
  watch: {
    startDate: function () {
      this.filterTimers();
    },
    endDate: function () {
      this.filterTimers();
    }
  },
  created() {
    this.setStartDate();
    this.setEndDate();
    this.fetchUser();
    this.fetchTimers();
  },
  methods: {
    ...mapActions(['ShowNotification']),
    setStartDate() {
      let day = 1;
      let month = new Date().getMonth() + 1;
      let year = new Date().getFullYear();

      const startDate = year + '-' + month + '-' + day;
      return (this.startDate = new Date(startDate).toISOString().substr(0, 10));
    },
    setEndDate() {
      return (this.endDate = new Date().toISOString().substr(0, 10));
    },
    async fetchUser() {
      try {
        const id = this.$route.params.id;
        const response = await PrintAPI.get(`/getUser/${id}`);
        this.user = response.data;
      } catch (e) {
        return e;
      }
    },
    async fetchTimers() {
      try {
        const response = await PrintAPI.get('/timers');
        const timers = response.data;

        const userTimers = timers.filter(
          (timer: timer) => timer.userId === this.user.id
        );

        this.allTimers = userTimers;
        this.filterTimers();
      } catch (e) {
        return e;
      }
    },
    filterTimers() {
      const sortedTimers = this.allTimers.sort((a: timer, b: timer) => {
        if (a.timeIn > b.timeIn) {
          return 1;
        } else {
          return -1;
        }
      });

      const filteredTimers = sortedTimers.filter((timer: timer) => {
        if (
          timer.timeIn.substr(0, 10) >= this.startDate &&
          timer.timeIn.substr(0, 10) <= this.endDate
        ) {
          return timer;
        }
      });

      let complete = [] as timer[];
      let incomplete = [] as timer[];

      filteredTimers.forEach((timer: timer) => {
        const id = timer.id;
        const date = this.computeDate(timer.timeIn);
        const timeIn = this.computeTime(timer.timeIn);

        if (timer.timeOut === null) {
          const timeOut = null;
          let newTimer = {
            id,
            date,
            timeIn,
            timeOut,
            noteIn: timer.noteIn,
            noteOut: timer.noteOut
          };

          incomplete.push(newTimer as timer);
        } else {
          const timeOut = this.computeTime(timer.timeOut);
          let newTimer = {
            id,
            date,
            timeIn,
            timeOut,
            noteIn: timer.noteIn,
            noteOut: timer.noteOut
          };

          complete.push(newTimer as timer);
        }
      });

      this.completeTimers = complete;
      this.incompleteTimers = incomplete;
    },
    async saveTimer(timer: timer) {
      try {
        let timeOut = null;
        let totalTime = 0;

        const timeInString = timer.date + 'T' + timer.timeIn;
        const timeIn = new Date(timeInString);

        if (timer.timeOut) {
          const timeOutString = timer.date + 'T' + timer.timeOut;
          timeOut = new Date(timeOutString);
          totalTime = (timeOut.getTime() - timeIn.getTime()) / 60 / 60 / 1000;
          timeOut = timeOut.toISOString();
        }

        await PrintAPI.put(`/editTimer/${timer.id}`, {
          timeIn: timeIn.toISOString(),
          timeOut: timeOut,
          totalTime: totalTime,
          noteIn: timer.noteIn,
          noteOut: timer.noteOut
        });

        this.ShowNotification({
          type: 'success',
          message: 'Timer saved successfully!'
        });
      } catch (e) {
        this.ShowNotification({
          type: 'error',
          message: 'Error saving timer'
        });
        return e;
      }
    },
    editForm() {
      if (this.edit) {
        this.edit = false;
      } else {
        this.edit = true;
      }
    },
    computeDate(dateString: string) {
      const dateArray = dateString.split('T');
      const date = dateArray[0];
      return date;
    },
    computeTime(dateString: string | null) {
      if (dateString === null) {
        return null;
      }
      const newDate = new Date(dateString).toLocaleString();
      const dateArray = newDate.split(' ');
      const time = dateArray[1];
      const timeArray = time.split(':');
      let hours = timeArray[0];
      let trueHours = '';

      if (dateArray[2] === 'PM' && Number(hours) < 12) {
        trueHours = (Number(hours) + 12).toString();
      } else if (Number(hours) === 12 && dateArray[2] === 'AM') {
        trueHours = '0';
      } else {
        trueHours = hours;
      }

      if (Number(trueHours) < 10) {
        trueHours = '0' + trueHours;
      }

      const minutes = timeArray[1];
      const newTime = trueHours + ':' + minutes;
      return newTime;
    },
    convertTime(time: string | null) {
      if (time === null) {
        return null;
      }

      let timeArray = time.split(':');
      let suffix = 'AM';

      if (Number(timeArray[0]) >= 12) {
        suffix = 'PM';
      }

      if (Number(timeArray[0]) > 12) {
        let newHour = Number(timeArray[0]) - 12;
        timeArray[0] = newHour.toString();
      } else if (Number(timeArray[0]) === 0) {
        timeArray[0] = '12';
      } else {
        let newHour = Number(timeArray[0]);
        timeArray[0] = newHour.toString();
      }

      const newTime = timeArray[0] + ':' + timeArray[1] + ' ' + suffix;
      return newTime;
    }
  }
});
