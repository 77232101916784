const twelveHours = 1000 * 60 * 60 * 12;

export function checkAuth(): void {
  let loginTime: string | null | number = localStorage.getItem('loginTime');

  if (loginTime) {
    // coerce loginTime to number
    loginTime = +loginTime;

    const currentTime = Date.now();
    const logoutTime = loginTime + twelveHours;

    if (currentTime > logoutTime) {
      timeoutUser();
    }
  }
}

export function timeoutUser(): void {
  localStorage.removeItem('authenticated');
  localStorage.removeItem('user');
  localStorage.removeItem('loginTime');
  window.location.href = '/';
}
