
import { defineComponent } from 'vue';
import { mapState } from 'vuex';
import { RootState } from '@/store/types';

export interface navOptions {
  jobsOpen: boolean;
  pricesOpen: boolean;
  usersOpen: boolean;
}
export default defineComponent({
  name: 'SidebarNav',
  data() {
    return {
      jobsOpen: false,
      pricesOpen: false,
      usersOpen: false
    };
  },
  computed: mapState<RootState>({
    userRole: (state: RootState) => state.user.role
  }),
  methods: {
    toggleNavOption(optionName: string) {
      this[optionName as keyof navOptions] =
        !this[optionName as keyof navOptions];
    }
  }
});
