
import { defineComponent } from 'vue';
import {
  fetchJobsInCurrentMonth,
  getDetailPanelJob
} from '@/services/jobs/jobs-data';
import { Job, DetailPanelData } from '@/services/jobs/types';
import { PanelBtn } from '@/store/types';
import { mapActions, mapGetters } from 'vuex';
import { PrintAPI } from '@/services/PrintAPIClient';

import PrimaryButton from '@/components/buttons/PrimaryButton.vue';
import Report from '@/modules/jobs/Report.vue';
import Table from '@/components/tables/Table.vue';
import TableDetail from '@/components/tables/TableDetail.vue';
import Loading from 'vue-loading-overlay';

export default defineComponent({
  name: 'Home',
  components: {
    PrimaryButton,
    Report,
    Table,
    TableDetail,
    Loading
  },
  data() {
    return {
      isLoading: false,
      noData: false,
      jobs: [] as Job[],
      jobProperties: [] as string[],
      jobTableHeadProperties: [] as string[],
      panelData: {},
      panelBtns: [] as PanelBtn[]
    };
  },
  computed: { ...mapGetters(['GetDetailPanelIsOpen']) },
  created() {
    this.getJobInMonth();
  },
  beforeMount() {
    this.CloseDetailPanel();
  },
  methods: {
    ...mapActions(['OpenDetailPanel', 'CloseDetailPanel', 'ShowNotification']),
    async getJobInMonth() {
      try {
        this.isLoading = true;
        const response = await fetchJobsInCurrentMonth();
        if (response.jobs.length == 0) {
          this.jobs = [];
          this.noData = true;
        } else {
          this.jobs = response.jobs;
        }
        this.jobProperties = response.jobProperties;
        this.jobTableHeadProperties = response.jobTableHeadProperties;
        this.isLoading = false;
      } catch (err) {
        return err;
      }
    },
    async openTableDetail(id: number) {
      const response = await getDetailPanelJob(id);

      this.panelData = response;

      const tertiaryBtnLabel =
        response.bodyData.Billed === 'Not Billed'
          ? 'Mark Billed'
          : 'Unmark Billed';

      this.panelBtns = [
        { type: 'tertiary', label: tertiaryBtnLabel },
        { type: 'primary', label: 'Edit' }
      ];

      this.OpenDetailPanel();
    },
    handlePrimaryBtn(id: number) {
      this.$router.push(`/edit-job/${id}`);
      this.CloseDetailPanel();
    },
    handleTertiaryBtn(resourceId: number, panelData: DetailPanelData) {
      panelData.bodyData.Billed === 'Not Billed'
        ? this.markJobBilled(resourceId)
        : this.unmarkJobBilled(resourceId);
    },
    async markJobBilled(id: number) {
      try {
        await PrintAPI.put(`/editJob/${id}`, {
          complete: new Date().toISOString()
        });
        this.ShowNotification({
          type: 'success',
          message: 'Job marked as BILLED successfully!'
        });
        this.getJobInMonth();
      } catch (error) {
        this.ShowNotification({
          type: 'error',
          message: 'Error marking job billed'
        });
        return error;
      }
    },
    async unmarkJobBilled(id: number) {
      if (
        window.confirm('Are you sure you want to mark this job as NOT billed?')
      ) {
        try {
          await PrintAPI.put(`/editJob/${id}`, {
            complete: null
          });
          this.ShowNotification({
            type: 'success',
            message: 'Job marked NOT BILLED successfully!'
          });
          this.getJobInMonth();
        } catch (error) {
          this.ShowNotification({
            type: 'error',
            message: 'Error unmarking job billed'
          });
          return error;
        }
      }
    }
  }
});
