
import { defineComponent } from 'vue';
import { PrintAPI } from '@/services/PrintAPIClient';
import { mapActions, mapGetters } from 'vuex';
import { InventoryType, InventoryItem } from '@/services/inventory/types';

import PrimaryButton from '@/components/buttons/PrimaryButton.vue';
import AccentButton from '@/components/buttons/AccentButton.vue';
import Input from '@/components/forms/Input.vue';
import Select from '@/components/forms/Select.vue';

export default defineComponent({
  name: 'EditInventory',
  components: {
    PrimaryButton,
    AccentButton,
    Input,
    Select
  },
  data() {
    return {
      inventoryItem: {} as InventoryItem,
      inventoryTypes: [] as InventoryType[]
    };
  },
  computed: {
    ...mapGetters(['GetCurrentUser'])
  },
  created() {
    this.getInventoryTypes();
    this.getInventoryItem();
    this.inventoryItem.updatedBy = this.GetCurrentUser.id;
  },
  methods: {
    ...mapActions(['ShowNotification']),
    async getInventoryTypes() {
      const response = await PrintAPI.get('/inventoryTypes');
      this.inventoryTypes = response.data.filter(
        (item: InventoryType) => item.deletedAt === null
      );
    },
    async getInventoryItem() {
      const response = await PrintAPI.get(
        `/getinventoryItem/${this.$route.params.id}`
      );
      this.inventoryItem = response.data;
    },
    async submitForm() {
      const { name, typeId, quantity, itemCostPerUnit } = this.inventoryItem;
      if (!name || !typeId || !quantity || !itemCostPerUnit) {
        this.ShowNotification({
          type: 'warning',
          message: 'Please fill in required fields'
        });
        return;
      }
      try {
        await PrintAPI.put(
          `/editInventoryItem/${this.$route.params.id}`,
          this.inventoryItem
        );
        this.ShowNotification({
          type: 'success',
          message: 'Inventory item has been modified and saved successfully!'
        });
        this.$router.go(-1);
      } catch (error) {
        this.ShowNotification({
          type: 'error',
          message: 'Error updating inventory item'
        });
        return error;
      }
    },
    async deleteInventoryItem() {
      if (
        window.confirm('Are you sure you want to archive this inventory item?')
      ) {
        try {
          await PrintAPI.delete(
            `/deleteInventoryItem/${this.$route.params.id}`
          );
          this.ShowNotification({
            type: 'success',
            message: 'Inventory item archived successfully!'
          });
          this.$router.go(-1);
        } catch (error) {
          this.ShowNotification({
            type: 'error',
            message: 'Error archiving inventory item'
          });
          return error;
        }
      }
    }
  }
});
