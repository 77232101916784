
import { defineComponent } from 'vue';
import { PrintAPI } from '@/services/PrintAPIClient';
import { timeObject } from '@/services/timeclock/types';
import { User } from '@/services/users/types';

import Input from '@/components/forms/Input.vue';
import Table from '@/components/tables/Table.vue';
import PrimaryButton from '@/components/buttons/PrimaryButton.vue';
import SecondaryButton from '@/components/buttons/SecondaryButton.vue';
import Loading from 'vue-loading-overlay';

interface UserRecord {
  id: number;
  name: string;
  hours: string;
  incomplete: string;
}

export default defineComponent({
  name: 'AdminViewHours',
  components: {
    Input,
    Table,
    PrimaryButton,
    SecondaryButton,
    Loading
  },
  data() {
    return {
      isLoading: false,
      startDate: '',
      endDate: '',
      allUsers: [] as User[],
      allTimers: [],
      users: {
        users: [] as UserRecord[],
        userProperties: [] as string[],
        userTableHeadProperties: [] as string[]
      },
      timers: []
    };
  },
  watch: {
    startDate: function () {
      this.filterTimers();
    },
    endDate: function () {
      this.filterTimers();
    },
    allTimers: function () {
      this.filterTimers();
    },
    timers: function () {
      this.filterUsers();
    }
  },
  created() {
    this.setStartDate();
    this.setEndDate();
    this.fetchUsers();
    this.fetchTimers();
  },
  methods: {
    setStartDate() {
      let day = 1;
      let month = String(new Date().getMonth() + 1);
      let year = new Date().getFullYear();
      if (month.length < 2) {
        month = '0' + month;
      }
      const startDate = year + '-' + month + '-' + day;
      return startDate;
    },
    setEndDate() {
      return (this.endDate = new Date().toISOString().substr(0, 10));
    },
    async fetchUsers() {
      try {
        const response = await PrintAPI.get('/users');
        this.allUsers = response.data.filter(
          (item: User) => item.deletedAt === null
        );
      } catch (e) {
        return e;
      }
    },
    async fetchTimers() {
      try {
        const response = await PrintAPI.get('/timers');
        this.allTimers = response.data;
      } catch (e) {
        return e;
      }
    },
    filterTimers() {
      const timers = this.allTimers.filter((timer: timeObject) => {
        if (
          timer.timeIn.substr(0, 10) >= this.startDate &&
          timer.timeIn.substr(0, 10) <= this.endDate
        ) {
          return timer;
        }
      });

      this.timers = timers;
      this.filterUsers();
    },
    async filterUsers() {
      try {
        this.isLoading = true;
        const users = this.allUsers;
        this.users.userProperties = ['name', 'hours', 'incomplete'];
        this.users.userTableHeadProperties = ['Name', 'Hours', 'Incomplete'];

        let timerIds = this.timers.map((timer: timeObject) => timer.userId);

        let usersWithTimers = [] as UserRecord[];

        users.forEach((user: User) => {
          let userRecord = {
            id: 0,
            name: '',
            hours: '',
            incomplete: 'NO'
          } as UserRecord;

          let numHours = 0;

          if (timerIds.includes(user.id)) {
            userRecord.id = user.id;
            userRecord.name = `${user.firstName} ${user.lastName}`;
          } else {
            return;
          }

          this.timers.forEach((timer: timeObject) => {
            if (timer.userId === user.id) {
              if (timer.timeOut) {
                const timeIn = new Date(timer.timeIn);
                const timeOut = new Date(timer.timeOut);
                const totalTime = timeOut.getTime() - timeIn.getTime();
                const hours = totalTime / (1000 * 3600);
                numHours += hours;
              } else {
                userRecord.incomplete = 'YES';
              }
            }
          });

          const tempHours = numHours.toFixed(2);
          userRecord.hours = tempHours;

          usersWithTimers.push(userRecord);
        });

        this.users.users = usersWithTimers;
        this.isLoading = false;
      } catch (e) {
        return e;
      }
    }
  }
});
