import { PrintAPI } from '../PrintAPIClient';
import { PO, User, POReturn, PODetailPanelReturn } from './types';
import { Company } from '../jobs/types';
import { ConvertDate } from '../utils-times';

const handlePOData = async (po: PO[]): Promise<PO[]> => {
  const companies = await PrintAPI.get('/companies');
  const users = await PrintAPI.get('/users');

  const POInfoArray = po.map((po: PO) => {
    const companyId = po.companyId;
    const userId = po.userId;
    let companyName = '';
    let userName = '';

    companies.data.forEach((company: Company) => {
      if (companyId === company.id) {
        companyName = company.name;
      }
    });

    users.data.forEach((user: User) => {
      if (userId === user.id) {
        userName = `${user.firstName} ${user.lastName}`;
      }
    });

    po.createdAt = ConvertDate(po.createdAt);

    const poInfo = {
      ...po,
      companyName,
      userName
    };
    return poInfo;
  });

  return POInfoArray;
};

export const fetchTablePOs = async (): Promise<POReturn> => {
  try {
    const pos = await PrintAPI.get('/pos');
    const POInfoArray = await handlePOData(pos.data);

    return {
      pos: POInfoArray,
      poProperties: ['po', 'companyName', 'name', 'createdAt', 'userName'],
      poTableHeadProperties: ['PO', 'Company', 'For', 'Added On', 'Added By']
    };
  } catch (error: any) {
    return error;
  }
};

export const getDetailPanelPO = async (
  id: number
): Promise<PODetailPanelReturn> => {
  try {
    const po = await PrintAPI.get(`/getPO/${id}`);
    const poArray = [po.data];
    const POInfoArray = await handlePOData(poArray);
    const poData = POInfoArray[0];

    const detailPanelData = {
      resourceId: id,
      headerData: {
        key1: 'Purchase Order',
        key2: `${poData.po}`
      },
      bodyData: {
        'Purchase Order': poData.po,
        Store: poData.companyName,
        For: poData.name,
        'Added On': poData.createdAt,
        'Added By': poData.userName
      }
    };

    return { detailPanelData, deleted: poData.deletedAt };
  } catch (error: any) {
    return error;
  }
};
