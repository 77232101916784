
import { defineComponent } from 'vue';
import { mapActions, mapGetters } from 'vuex';
import { PrintAPI } from '@/services/PrintAPIClient';
import { ConvertDate } from '@/services/utils-times';
import { Company } from '@/services/jobs/types';
import { PanelBtn } from '@/store/types';

import SearchBar from '@/components/searches/SearchBar.vue';
import PrimaryButton from '@/components/buttons/PrimaryButton.vue';
import Table from '@/components/tables/Table.vue';
import TableDetail from '@/components/tables/TableDetail.vue';
import Loading from 'vue-loading-overlay';

export default defineComponent({
  name: 'ManageCompany',
  components: {
    SearchBar,
    PrimaryButton,
    Table,
    TableDetail,
    Loading
  },
  data() {
    return {
      isLoading: false,
      noData: false,
      companies: [] as Company[],
      companyProperties: ['name', 'shortCode', 'isYag', 'showOnOrderForm'],
      companyTableHeadProperties: [
        'Name',
        'Short Code',
        'Is YAG',
        'Show On Order Form'
      ],
      timer: null as number | null,
      debouncedSearchInput: '',
      active: true,
      panelData: {},
      panelBtns: [] as PanelBtn[]
    };
  },
  computed: {
    ...mapGetters(['GetDetailPanelIsOpen']),
    filterCompanies(): Company[] {
      let returnCompanies = this.companies;
      let filteredCompanies = this.companies;

      if (this.active) {
        filteredCompanies = filteredCompanies.filter(
          (company: Company) => company.deletedAt === null
        );
      } else {
        filteredCompanies = filteredCompanies.filter(
          (company: Company) => company.deletedAt != null
        );
      }

      returnCompanies = filteredCompanies;

      if (this.debouncedSearchInput != '' && this.debouncedSearchInput) {
        returnCompanies = this.companies.filter((item: Company) => {
          return this.debouncedSearchInput
            .toLowerCase()
            .split(' ')
            .every((v) => JSON.stringify(item).toLowerCase().includes(v));
        });
      }

      return returnCompanies;
    },
    searchInput: {
      get(): string {
        return this.debouncedSearchInput;
      },
      set(val: string) {
        if (this.timer) clearTimeout(this.timer);
        this.timer = setTimeout(() => {
          this.debouncedSearchInput = val;
        }, 500);
      }
    }
  },
  created() {
    this.fetchCompanies();
  },
  methods: {
    ...mapActions(['OpenDetailPanel', 'CloseDetailPanel', 'ShowNotification']),
    async fetchCompanies() {
      try {
        this.isLoading = true;
        const response = await PrintAPI.get('/companies');
        this.companies = response.data;
        this.isLoading = false;
      } catch (e) {
        return e;
      }
    },
    async fetchCompany(id: number) {
      try {
        const response = await PrintAPI.get(`/getCompany/${id}`);
        return response;
      } catch (e) {
        return e;
      }
    },
    async openTableDetail(id: number) {
      try {
        const { data } = await this.fetchCompany(id);

        this.panelData = {
          resourceId: id,
          headerData: {
            key1: 'Company',
            key2: data.name
          },
          bodyData: {
            Name: data.name,
            'Short Code': data.shortCode,
            'Is YAG': data.isYag,
            'Show On Order Form': data.showOnOrderForm,
            'Last PO Number': data.lastPONumber,
            Archived: data.deletedAt ? ConvertDate(data.deletedAt) : 'Active'
          }
        };

        this.panelBtns = [{ type: 'primary', label: 'Edit' }];

        if (data.deletedAt) {
          const tertiaryBtn = { type: 'tertiary', label: 'Recover' };
          this.panelBtns.unshift(tertiaryBtn);
        } else {
          const accentBtn = { type: 'accent', label: 'Archive' };
          this.panelBtns.unshift(accentBtn);
        }

        this.OpenDetailPanel();
      } catch (e) {
        return e;
      }
    },
    handlePrimaryBtn(id: number) {
      this.$router.push(`/edit-company/${id}`);
      this.CloseDetailPanel();
    },
    async deleteCompany(id: number) {
      if (window.confirm('Are you sure you want to archive this company?')) {
        try {
          await PrintAPI.delete(`/deleteCompany/${id}`);
          this.ShowNotification({
            type: 'success',
            message: 'Company successfully archived!'
          });
          this.fetchCompanies();
        } catch (e) {
          this.ShowNotification({
            type: 'error',
            message: 'Error archiving company.'
          });
          return e;
        }
      }
    },
    async recoverCompany(id: number) {
      try {
        await PrintAPI.put(`/recoverCompany/${id}`);
        this.ShowNotification({
          type: 'success',
          message: 'Company restored successfully!'
        });
        this.fetchCompanies();
      } catch (e) {
        this.ShowNotification({
          type: 'error',
          message: 'Error restoring company'
        });
        return e;
      }
    }
  }
});
