import { StoreOptions, createStore } from 'vuex';
import { RootState, User } from './types';
import { PrintAPI } from '@/services/PrintAPIClient';

// ---- Modules ----
import tableDetail from './modules/table-detail';
import notifications from './modules/notifications';

const store: StoreOptions<RootState> = {
  /**AUTHENTICATION & CURRENT USER STATE*/
  state: {
    user: {
      authenticated: false,
      firstName: '',
      lastName: '',
      username: '',
      id: null,
      role: ''
    } as User
  } as RootState,
  getters: {
    GetAuth: (state) => state.user.authenticated,
    GetRole: (state) => state.user.role,
    GetCurrentUser: (state) => state.user
  },
  actions: {
    async LoginUser(
      { commit },
      credentials: { username: string; password: string }
    ) {
      credentials.username = credentials.username.toLowerCase();
      const response = await PrintAPI.post('/login', credentials);

      //find current time and set to a string
      const now = `${Date.now()}`;

      localStorage.setItem('loginTime', now);
      localStorage.setItem('authenticated', response.data.authenticated);
      localStorage.setItem('user', JSON.stringify(response.data));

      commit('SetUser', response.data);
    },
    async LogoutUser({ commit }) {
      try {
        await PrintAPI.post('/logout');
        commit('RemoveUser');
      } catch (e) {
        console.log(e);
      }
    }
  },
  mutations: {
    InitializeStore: (state) => {
      const userString = localStorage.getItem('user');
      if (userString) {
        const user = JSON.parse(userString);
        state.user = user;
      }
    },
    SetUser: (state, user) => (state.user = user),
    RemoveUser: () => {
      localStorage.removeItem('authenticated');
      localStorage.removeItem('user');
      localStorage.removeItem('loginTime');
      window.location.href = '/';
    }
  },

  /**APPLICATION STATE MODULES */
  modules: {
    tableDetail,
    notifications
  }
};

export default createStore(store);
