
import { defineComponent } from 'vue';
import { mapActions } from 'vuex';
import { PrintAPI } from '@/services/PrintAPIClient';

import Input from '@/components/forms/Input.vue';
import Checkbox from '@/components/forms/Checkbox.vue';
import AccentButton from '@/components/buttons/AccentButton.vue';
import PrimaryButton from '@/components/buttons/PrimaryButton.vue';

interface UpdateCompany {
  name: string;
  isYag: boolean;
  showOnOrderForm: boolean;
}

export default defineComponent({
  name: 'EditCompany',
  components: {
    Input,
    Checkbox,
    AccentButton,
    PrimaryButton
  },
  data() {
    return {
      company: {
        name: '',
        isYag: false,
        showOnOrderForm: false
      } as UpdateCompany
    };
  },
  created() {
    this.getCompany();
  },
  methods: {
    ...mapActions(['ShowNotification']),
    async getCompany() {
      try {
        const { data } = await PrintAPI.get(
          `/getCompany/${this.$route.params.id}`
        );
        this.company = {
          name: data.name,
          isYag: data.isYag,
          showOnOrderForm: data.showOnOrderForm
        };
      } catch (e) {
        return e;
      }
    },
    async submitForm(data: UpdateCompany) {
      if (!this.company.name) {
        this.ShowNotification({
          type: 'warning',
          message: 'Please fill out required fields'
        });

        return;
      }
      try {
        await PrintAPI.put(`/editCompany/${this.$route.params.id}`, data);
        this.ShowNotification({
          type: 'success',
          message: 'Company modified and saved successfully!'
        });
        this.$router.go(-1);
      } catch (e) {
        this.ShowNotification({
          type: 'error',
          message: 'Error updating company.'
        });
        return e;
      }
    },
    async deleteCompany() {
      try {
        await PrintAPI.delete(`/deleteCompany/${this.$route.params.id}`);
      } catch (e) {
        this.ShowNotification({
          type: 'error',
          message: 'Error archiving company'
        });
      }
    }
  }
});
