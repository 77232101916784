import { Module, GetterTree, ActionTree, MutationTree } from 'vuex';
import { RootState, tableDetailState } from '../types';

const initializeState = (): tableDetailState => {
  return {
    detailPanelIsOpen: false
  };
};

const state: tableDetailState = initializeState();

const getters: GetterTree<tableDetailState, RootState> = {
  GetDetailPanelIsOpen: (state) => state.detailPanelIsOpen
};

const actions: ActionTree<tableDetailState, RootState> = {
  OpenDetailPanel: ({ commit }) => {
    commit('SetDetailPanelOpen');
  },
  CloseDetailPanel: ({ commit }) => {
    commit('SetDetailPanelClose');
  }
};

const mutations: MutationTree<tableDetailState> = {
  SetDetailPanelOpen: (state) => {
    state.detailPanelIsOpen = true;
  },
  SetDetailPanelClose: (state) => {
    Object.assign(state, initializeState());
  }
};

const tableDetail: Module<tableDetailState, RootState> = {
  state,
  getters,
  actions,
  mutations
};

export default tableDetail;
