
import { defineComponent } from 'vue';
import { mapActions, mapGetters } from 'vuex';
import { PrintAPI } from '@/services/PrintAPIClient';

import Input from '@/components/forms/Input.vue';
import Select from '@/components/forms/Select.vue';
import Checkbox from '@/components/forms/Checkbox.vue';
import AccentButton from '@/components/buttons/AccentButton.vue';
import PrimaryButton from '@/components/buttons/PrimaryButton.vue';

interface UpdateUser {
  firstName: string;
  lastName: string;
  accountName: string;
  username: string;
  password?: string;
  email: string;
  role: string;
  hourly: boolean;
}

export default defineComponent({
  name: 'EditUser',
  components: {
    Input,
    Select,
    Checkbox,
    AccentButton,
    PrimaryButton
  },
  data() {
    return {
      user: {
        firstName: '',
        lastName: '',
        accountName: '',
        username: '',
        password: '',
        email: '',
        role: '',
        hourly: true
      } as UpdateUser,
      passwordAssert: ''
    };
  },
  computed: {
    ...mapGetters(['GetCurrentUser'])
  },
  created() {
    this.getUser();
  },
  methods: {
    ...mapActions(['ShowNotification']),
    async getUser() {
      try {
        const { data } = await PrintAPI.get(
          `/getUser/${this.$route.params.id}`
        );
        this.user = {
          firstName: data.firstName,
          lastName: data.lastName,
          accountName: data.accountName,
          username: data.username,
          password: '',
          email: data.email,
          role: data.role,
          hourly: data.hourly
        };
      } catch (error) {
        return error;
      }
    },
    checkPasswordUpdateBeforeFormSubmit() {
      const reqBody = this.user;

      if (!this.user.password) {
        // there is no updated password to include in request
        // send the request without the password value
        delete reqBody.password;
        this.submitForm(reqBody);
      } else if (this.user.password) {
        // there is an updated password
        // check that both inputed passwords match
        if (this.passwordAssert !== this.user.password) {
          this.ShowNotification({
            type: 'warning',
            message: 'Passwords must match'
          });
          return;
        } else {
          // there is an updated password
          // and both inputed passwords match
          // so we can send the request
          this.submitForm(reqBody);
        }
      }
    },
    async submitForm(reqBody: UpdateUser) {
      const { firstName, lastName, accountName, username, email, role } =
        this.user;
      if (
        !firstName ||
        !lastName ||
        !accountName ||
        !username ||
        !email ||
        !role
      ) {
        this.ShowNotification({
          type: 'warning',
          message: 'Please fill in all required fields'
        });
        return;
      }
      try {
        await PrintAPI.put(`/editUser/${this.$route.params.id}`, reqBody);
        this.ShowNotification({
          type: 'success',
          message: 'The user has been modified and saved successfully!'
        });
        this.$router.go(-1);
      } catch (e) {
        this.ShowNotification({
          type: 'error',
          message: 'Error updating user'
        });
      }
    },
    async deleteUser() {
      try {
        const id = this.$route.params.id;
        await PrintAPI.delete(`/deleteUser/${id}`);
      } catch (e) {
        this.ShowNotification({
          type: 'error',
          message: 'Error archiving user'
        });
      }
    }
  }
});
