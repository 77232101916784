
import { defineComponent } from 'vue';
import { mapGetters } from 'vuex';

export default defineComponent({
  name: 'NotificationBar',
  data() {
    return {
      bgColor: ''
    };
  },
  computed: {
    ...mapGetters([
      'GetShowNotification',
      'GetNotificationType',
      'GetNotificationMessage'
    ])
  },
  watch: {
    GetShowNotification: function () {
      switch (this.GetNotificationType) {
        case 'success':
          this.bgColor = 'bg-green-500';
          break;

        case 'error':
          this.bgColor = 'bg-red-400';
          break;

        case 'warning':
          this.bgColor = 'bg-yellow-400';
          break;

        default:
          break;
      }
    }
  }
});
