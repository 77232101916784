import { toDisplayString as _toDisplayString, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Transition as _Transition, withCtx as _withCtx } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createBlock(_Transition, { name: "fade" }, {
    default: _withCtx(() => [
      (_ctx.GetShowNotification)
        ? (_openBlock(), _createBlock("div", {
            key: 0,
            class: ["h-12 w-full text-white text-xl fixed bottom-0 text-center p-2", `${_ctx.bgColor}`]
          }, _toDisplayString(_ctx.GetNotificationMessage), 3))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }))
}