
import { defineComponent } from 'vue';
import { mapActions } from 'vuex';
import { PrintAPI } from '@/services/PrintAPIClient';
import { Company } from '@/services/jobs/types';
import { PO } from '@/services/purchase-orders/types';

import Input from '@/components/forms/Input.vue';
import Select from '@/components/forms/Select.vue';
import PrimaryButton from '@/components/buttons/PrimaryButton.vue';
import AccentButton from '@/components/buttons/AccentButton.vue';

export default defineComponent({
  name: 'EditPurchaseOrder',
  components: {
    Input,
    Select,
    PrimaryButton,
    AccentButton
  },
  data() {
    return {
      companies: [] as Company[],
      po: {} as PO
    };
  },
  created() {
    this.getCompanies();
    this.getPO();
  },
  methods: {
    ...mapActions(['ShowNotification']),
    async getPO() {
      const response = await PrintAPI.get(`/getPO/${this.$route.params.id}`);
      this.po = response.data;
    },
    async getCompanies() {
      const response = await PrintAPI.get('/companies');
      this.companies = response.data;
    },
    async submitForm() {
      try {
        await PrintAPI.put(`/editPO/${this.$route.params.id}`, this.po);

        this.ShowNotification({
          type: 'success',
          message: 'Purchase Order has been modified and saved successfully!'
        });
        this.$router.go(-1);
      } catch (error) {
        this.ShowNotification({
          type: 'error',
          message: 'Error updating purchase order'
        });
        return error;
      }
    },
    async deletePO() {
      if (
        window.confirm('Are you sure you want to archive this purchase order?')
      ) {
        try {
          await PrintAPI.delete(`/deletePO/${this.$route.params.id}`);
          this.ShowNotification({
            type: 'success',
            message: 'Purchase Order archived successfully!'
          });
          this.$router.go(-1);
        } catch (error) {
          this.ShowNotification({
            type: 'error',
            message: 'Error archiving purchase order'
          });
          return error;
        }
      }
    }
  }
});
