
import { defineComponent } from 'vue';
import { mapActions } from 'vuex';
import { PrintAPI } from '@/services/PrintAPIClient';
import { User } from '@/services/users/types';

import Select from '@/components/forms/Select.vue';
import Input from '@/components/forms/Input.vue';
import Textarea from '@/components/forms/Textarea.vue';
import AccentButton from '@/components/buttons/AccentButton.vue';
import TertiaryButton from '@/components/buttons/TertiaryButton.vue';
import PrimaryButton from '@/components/buttons/PrimaryButton.vue';

export default defineComponent({
  name: 'AdminAddHours',
  components: {
    Select,
    Input,
    Textarea,
    AccentButton,
    TertiaryButton,
    PrimaryButton
  },
  data() {
    return {
      users: [] as User[],
      data: {
        userId: 0,
        date: '',
        timeIn: '',
        timeOut: '',
        totalTime: 0,
        isHoliday: false,
        isVacation: false,
        noteIn: ''
      }
    };
  },
  watch: {
    'data.isHoliday': function (val) {
      if (val === 'true') {
        this.data.isHoliday = true;
      }

      if (val === 'false') {
        this.data.isHoliday = false;
      }
    },
    'data.isVacation': function (val) {
      if (val === 'true') {
        this.data.isVacation = true;
      }

      if (val === 'false') {
        this.data.isVacation = false;
      }
    }
  },
  created() {
    this.getUsers();
  },
  methods: {
    ...mapActions(['ShowNotification']),
    async getUsers() {
      try {
        const response = await PrintAPI.get('/users');
        this.users = response.data.filter(
          (user: User) => user.deletedAt === null
        );
      } catch (e) {
        return e;
      }
    },
    async submitForm(mode: string) {
      const { userId, date, timeIn, timeOut } = this.data;
      if (!userId || !date || !timeIn || !timeOut) {
        this.ShowNotification({
          type: 'warning',
          message: 'Please fill in all required fields'
        });
        return;
      }

      //calculate totalTime in milliseconds
      //and add to data object to send with request
      const inDateAndTime = `${this.data.date} ${timeIn}`;
      const inTime = new Date(inDateAndTime);
      const outDateAndTime = `${this.data.date} ${timeOut}`;
      const outTime = new Date(outDateAndTime);

      const totalTime = (outTime.getTime() - inTime.getTime()) / 60 / 60 / 1000;
      this.data.totalTime = totalTime;

      try {
        await PrintAPI.post('/createTimer', this.data);
        this.ShowNotification({
          type: 'success',
          message: 'Hours added successfully!'
        });
        if (mode === 'save') this.$router.push('/view-hours');
        if (mode === 'add') this.$router.go(0);
      } catch (e) {
        this.ShowNotification({
          type: 'error',
          message: 'Error adding hours.'
        });
        return e;
      }
    }
  }
});
