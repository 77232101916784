
import { defineComponent } from 'vue';
import { mapGetters } from 'vuex';
import LoginLayout from '@/layouts/LoginLayout.vue';
import DashboardLayout from '@/layouts/DashboardLayout.vue';

export default defineComponent({
  name: 'App',
  components: {
    LoginLayout,
    DashboardLayout
  },
  computed: {
    ...mapGetters(['GetAuth'])
  },
  beforeCreate() {
    this.$store.commit('InitializeStore');
  }
});
