
import { defineComponent } from 'vue';
import SidebarNav from '@/global/SidebarNav.vue';
//import Timeclock from '@/modules/timeclock/Timeclock.vue';
import NewTimeSystemLink from '@/modules/timeclock/NewTimeSystemLink.vue';

export default defineComponent({
  name: 'Sidebar',
  components: {
    SidebarNav,
    // Timeclock,
    NewTimeSystemLink
  },
  data() {
    return {
      sidebarOpen: true,
      appVersion: process.env.VUE_APP_VERSION
    };
  },
  computed: {
    getClass() {
      if (this.sidebarOpen) {
        return 'w-80';
      } else {
        return 'w-16';
      }
    }
  },
  methods: {
    toggleSidebar() {
      this.sidebarOpen = !this.sidebarOpen;
    }
  }
});
