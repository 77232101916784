
import { defineComponent } from 'vue';
import { PrintAPI } from '@/services/PrintAPIClient';
import { mapActions, mapGetters } from 'vuex';
import { InventoryType, InventoryItem } from '@/services/inventory/types';

import PrimaryButton from '@/components/buttons/PrimaryButton.vue';
import AccentButton from '@/components/buttons/AccentButton.vue';
import TertiaryButton from '@/components/buttons/TertiaryButton.vue';
import Input from '@/components/forms/Input.vue';
import Select from '@/components/forms/Select.vue';

export default defineComponent({
  name: 'AddInventory',
  components: {
    PrimaryButton,
    AccentButton,
    TertiaryButton,
    Input,
    Select
  },
  data() {
    return {
      inventoryItem: {} as InventoryItem,
      inventoryTypes: [] as InventoryType[]
    };
  },
  computed: {
    ...mapGetters(['GetCurrentUser'])
  },
  created() {
    this.getInventoryTypes();
    this.inventoryItem.updatedBy = this.GetCurrentUser.id;
  },
  methods: {
    ...mapActions(['ShowNotification']),
    async getInventoryTypes() {
      const response = await PrintAPI.get('inventoryTypes');
      this.inventoryTypes = response.data.filter(
        (item: InventoryType) => item.deletedAt === null
      );
    },
    async submitForm(mode: string) {
      const { name, typeId, quantity, itemCostPerUnit } = this.inventoryItem;
      if (!name || !typeId || !quantity || !itemCostPerUnit) {
        this.ShowNotification({
          type: 'warning',
          message: 'Please fill in required fields'
        });

        return;
      }
      try {
        await PrintAPI.post('/createInventoryItem', this.inventoryItem);
        this.ShowNotification({
          type: 'success',
          message: 'Inventory added successfully!'
        });
        if (mode === 'save') this.$router.go(-1);
        if (mode === 'add') this.$router.go(0);
      } catch (error) {
        this.ShowNotification({
          type: 'error',
          message: 'Error updating inventory.'
        });
        return error;
      }
    }
  }
});
