
import { defineComponent } from 'vue';
import { PrintAPI } from '@/services/PrintAPIClient';
import PieChart from './PieChart.vue';
import { Company, Paper } from '@/services/jobs/types';
import { fetchJobsDate } from '@/services/jobs/jobs-data';

export default defineComponent({
  name: 'Report',
  components: { PieChart },
  data() {
    return {
      startDate: this.getStartDate(),
      endDate: this.getEndDate(),
      company: 'All',
      paper: '',
      companies: [] as Company[],
      papers: [] as Paper[],
      data: [] as number[],
      valid: true
    };
  },
  watch: {
    //if anything change: fetch jobs accrodingly
    startDate: function () {
      this.fetchJobs();
    },
    endDate: function () {
      this.fetchJobs();
    },
    company: function () {
      this.fetchJobs();
    },
    paper: function () {
      this.fetchJobs();
    }
  },
  beforeMount() {
    this.company = 'All';
    this.paper = 'All';
    this.fetchCompanies();
    this.fetchPapers();
  },
  methods: {
    getStartDate() {
      const lastMonth = new Date();
      let day = String(lastMonth.getDate());
      let month = String(lastMonth.getMonth());
      let year = lastMonth.getFullYear();
      //formatting the date
      if (month.length < 2) {
        month = '0' + month;
      }
      if (day.length < 2) {
        day = '0' + day;
      }
      const startDate = year + '-' + month + '-' + day;
      return startDate;
    },
    getEndDate() {
      const today = new Date();
      let day = String(today.getDate());
      let month = String(today.getMonth() + 1);
      let year = today.getFullYear();
      //formatting the date
      if (month.length < 2) {
        month = '0' + month;
      }
      if (day.length < 2) {
        day = '0' + day;
      }
      const endDate = year + '-' + month + '-' + day;
      return endDate;
    },
    async fetchJobs() {
      //fetch data based on given date range, company and paper
      const response = await fetchJobsDate(
        this.startDate,
        this.endDate,
        this.company,
        this.paper
      );
      if (response.sales == null || response.cost == 0) {
        this.valid = false;
      } else {
        this.valid = true;
        this.data = [];
        //floor to two decimal digits
        const fixedSales = Number(Number(response.sales).toFixed(2));
        const fixedCost = Number(Number(response.cost).toFixed(2));

        this.data.push(fixedSales);
        this.data.push(fixedCost);
      }
    },
    async fetchCompanies() {
      //fetch list of companies
      const response = await PrintAPI.get('/companies');
      this.companies = response.data.filter(
        (company: Company) => company.deletedAt === null
      );
    },
    async fetchPapers() {
      //fetch list of papers
      const response = await PrintAPI.get('/papers');
      this.papers = response.data.filter(
        (paper: Paper) => paper.deletedAt === null
      );
    }
  }
});
