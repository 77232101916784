
import { defineComponent } from 'vue';
import { exportCSVFile } from '@/services/utils-csv-export';
import { fetchTableTimers } from '@/services/timeclock/timeclock-data';
import { timeObject } from '@/services/timeclock/types';

import SecondaryButton from '@/components/buttons/SecondaryButton.vue';
import Table from '@/components/tables/Table.vue';
import Loading from 'vue-loading-overlay';

export default defineComponent({
  name: 'TimeclockHistoryDownload',
  components: {
    SecondaryButton,
    Table,
    Loading
  },
  data() {
    return {
      isLoading: false,
      timers: [] as timeObject[],
      timerProperties: [] as string[],
      timerTableHeadProperties: [] as string[],
      debounceTimer: null as number | null,
      debouncedSearchInput: '',
      filename: ''
    };
  },
  computed: {
    filterTimers(): timeObject[] {
      let returnTimers = this.timers;
      let filteredTimers = this.timers;

      returnTimers = filteredTimers;

      if (this.debouncedSearchInput != '' && this.debouncedSearchInput) {
        returnTimers = filteredTimers.filter((item) => {
          return this.debouncedSearchInput
            .toLowerCase()
            .split('')
            .every((v) => JSON.stringify(item).toLowerCase().includes(v));
        });
      }

      return returnTimers;
    },
    searchInput: {
      get(): string {
        return this.debouncedSearchInput;
      },
      set(val: string) {
        if (this.debounceTimer) clearTimeout(this.debounceTimer);
        this.debounceTimer = setTimeout(() => {
          this.debouncedSearchInput = val;
        }, 500);
      }
    }
  },
  created() {
    this.fetchTimers();
  },
  methods: {
    async fetchTimers() {
      try {
        this.isLoading = true;
        const response = await fetchTableTimers();
        this.timers = response.timers;
        this.timerProperties = response.timerProperties;
        this.timerTableHeadProperties = response.timerTableHeadProperties;
        this.isLoading = false;
      } catch (error) {
        return error;
      }
    },
    download() {
      const headers = {
        createdByName: 'Created By',
        date: 'Date',
        timeIn: 'Time In',
        noteIn: 'Note In',
        timeOut: 'Time Out',
        noteOut: 'Note Out',
        totalTime: 'Total Time',
        isVacation: 'Is Vacation',
        isHoliday: 'Is Holiday',
        approvedOn: 'Approved On',
        approvedBy: 'Approved By'
      };

      const fileTitle = 'print&main-timeclock-history';
      exportCSVFile(headers, this.timers, fileTitle);
    }
  }
});
