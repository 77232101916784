
import { defineComponent } from 'vue';
import { mapActions } from 'vuex';
import { PrintAPI } from '@/services/PrintAPIClient';

import Input from '@/components/forms/Input.vue';
import PrimaryButton from '@/components/buttons/PrimaryButton.vue';
import TertiaryButton from '@/components/buttons/TertiaryButton.vue';

export default defineComponent({
  name: 'ForgotPassword',
  components: {
    Input,
    PrimaryButton,
    TertiaryButton
  },
  data() {
    return {
      email: ''
    };
  },
  methods: {
    ...mapActions(['ShowNotification']),
    async submitForm() {
      try {
        await PrintAPI.post('/password-reset-request', { email: this.email });
        this.ShowNotification({
          type: 'success',
          message:
            'Email sent. Please check your email for your password reset link.'
        });
      } catch (error: any) {
        console.log(error.response.data.message);
        this.ShowNotification({
          type: 'error',
          message: error.response.data.message
        });
      }
    }
  }
});
