
import { defineComponent } from 'vue';
import { PrintAPI } from '@/services/PrintAPIClient';

import Select from '@/components/forms/Select.vue';
import Input from '@/components/forms/Input.vue';

export default defineComponent({
  name: 'PrintCalculator',
  components: {
    Select,
    Input
  },
  data() {
    return {
      mailerType: '',
      quantity: 0,
      pricePer: 0,
      setup: 50,
      total: 0,
      error: ''
    };
  },
  watch: {
    mailerType: function () {
      this.calculate();
    },
    quantity: function () {
      this.calculate();
    }
  },
  methods: {
    async calculate() {
      try {
        const response = await PrintAPI.post('/calculateMailer', {
          mailerType: this.mailerType,
          quantity: this.quantity
        });
        this.pricePer = response.data.pricePerPage;
        this.setup = response.data.setup;
        this.total = response.data.totalPrice;
        this.error = '';
      } catch (error) {
        this.error = error.response.data.message;
        this.pricePer = 0;
        this.setup = 0;
        this.total = 0;
      }
    }
  }
});
