import { PrintAPI } from '../PrintAPIClient';
import { JobsReturn, Job, DetailPanelData } from './types';
import { ConvertDate } from '../utils-times';

export const fetchTableJobs = async (): Promise<JobsReturn> => {
  try {
    const response = await PrintAPI.get('/jobs');
    const jobs = response.data.jobs;

    return formatJobsTable(jobs);
  } catch (error: any) {
    return error;
  }
};

export const fetchJobsInCurrentMonth = async (): Promise<JobsReturn> => {
  try {
    const response = await PrintAPI.get(`/jobs/date/month`);
    const jobs = response.data.jobs.filter(
      (job: Job) => job.deletedAt === null
    );

    return formatJobsTable(jobs);
  } catch (error: any) {
    return error;
  }
};

export const fetchJobsPagination = async (
  page: number
): Promise<JobsReturn> => {
  try {
    const response = await PrintAPI.get(`/jobs/${page}`);
    const jobs = response.data.jobs;

    return formatJobsTable(jobs);
  } catch (error: any) {
    return error;
  }
};

export const formatJobsTable = (data: Job[]): JobsReturn => {
  const jobsInfoArray = data.map((job: Job) => {
    const companyName = job.company.name;
    const departmentName = job.department ? job.department.name : 'N/A';
    const datePrinted = job.datePrinted
      ? ConvertDate(job.datePrinted)
      : 'Unknown';
    const completedBy = job.completedBy.firstName
      ? `${job.completedBy.firstName} ${job.completedBy.lastName}`
      : 'Unknown';
    const paperName = job.paper ? job.paper.paperName : '';
    const paperSize = job.paper ? job.paper.paperSize : '';

    const print = Number(job.print);
    const newPrint = print.toFixed(2);
    const postage = Number(job.postage);
    const newPostage = postage.toFixed(2);
    const cost = Number(job.cost);
    const newCost = cost.toFixed(2);

    const jobInfo = {
      ...job,
      companyName,
      departmentName,
      datePrinted,
      completedBy,
      paperSize,
      paperName,
      print: `$${newPrint}`,
      postage: `$${newPostage}`,
      cost: `$${newCost}`
    };

    return jobInfo;
  });

  return {
    jobs: jobsInfoArray,
    jobProperties: [
      'id' /**Job# */,
      'companyName',
      'departmentName',
      'jobDescription',
      'up',
      'datePrinted',
      'completedBy',
      'quantity',
      'paperSize',
      'paperName',
      'x_x',
      'notes',
      'print',
      'postage',
      'cost'
    ],
    jobTableHeadProperties: [
      'Job#',
      'Company',
      'Dept',
      'Job Description',
      'UP',
      'Date Printed',
      'Completed By',
      'Quantity',
      'Finished Size',
      'Paper',
      'X/X',
      'Notes',
      'Customer Price',
      'Postage',
      'P&M Cost'
    ]
  };
};

export const fetchJobsDate = async (
  start: string,
  end: string,
  company: string,
  paper: string
): Promise<any> => {
  try {
    const response = await PrintAPI.post(`/jobs/date/range`, {
      startDate: start,
      endDate: end,
      company: company,
      paper: paper
    });
    return {
      sales: response.data.jobData.print,
      cost: response.data.jobData.cost
    };
  } catch (error: any) {
    return error;
  }
};

export const getDetailPanelJob = async (
  id: number
): Promise<DetailPanelData> => {
  try {
    const { data } = await PrintAPI.get(`/getJob/${id}`);

    let departmentName = 'N/A';
    let billed = 'Not Billed';
    let datePrinted = 'Unknown';
    let completedBy = 'Unknown';
    let quantity = 0;
    let x_x = 'N/A';
    let cuts = 'N/A';
    let paperName = 'N/A';
    let paperSize = 'N/A';

    if (data.department?.name) {
      departmentName = data.department.name;
    }

    if (data.job.complete) {
      billed = ConvertDate(data.job.complete);
    }

    if (data.job.datePrinted) {
      datePrinted = ConvertDate(data.job.datePrinted);
    }

    if (data.completedBy?.firstName) {
      const { firstName, lastName } = data.completedBy;
      completedBy = `${firstName} ${lastName}`;
    }

    if (data.job.quantity) {
      quantity = data.job.quantity;
    }

    if (data.job.x_x) {
      x_x = data.job.x_x;
    }

    if (data.job.cuts) {
      cuts = data.job.cuts;
    }

    if (data.paper?.paperName) {
      paperName = data.paper.paperName;
    }

    if (data.paper?.paperSize) {
      paperSize = data.paper.paperSize;
    }

    const print = Number(data.job.print);
    const newPrint = print.toFixed(2);
    const postage = Number(data.job.postage);
    const newPostage = postage.toFixed(2);
    const cost = Number(data.job.cost);
    const newCost = cost.toFixed(2);

    const detailPanelData: DetailPanelData = {
      resourceId: id,
      headerData: {
        key1: 'Job',
        key2: `Job# ${data.job.id}`
      },
      bodyData: {
        'Job#': data.job.id,
        Company: data.company.name,
        Department: departmentName,
        Description: data.job.jobDescription,
        Billed: billed,
        'Date Printed': datePrinted,
        'Completed By': completedBy,
        Quantity: quantity,
        'Finished Size': paperSize,
        Paper: paperName,
        X_X: x_x,
        Cuts: cuts,
        'Customer Price': `$${newPrint}`,
        Postage: `$${newPostage}`,
        'Print & Main Cost': `$${newCost}`,
        Notes: data.job.notes
      }
    };

    return detailPanelData;
  } catch (error: any) {
    return error;
  }
};
