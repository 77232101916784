
import { defineComponent } from 'vue';
import { mapActions } from 'vuex';
import { PrintAPI } from '@/services/PrintAPIClient';

import Input from '@/components/forms/Input.vue';
import AccentButton from '@/components/buttons/AccentButton.vue';
import PrimaryButton from '@/components/buttons/PrimaryButton.vue';

export default defineComponent({
  name: 'EditDepartment',
  components: {
    Input,
    AccentButton,
    PrimaryButton
  },
  data() {
    return {
      department: {
        name: ''
      }
    };
  },
  created() {
    this.getDepartment();
  },
  methods: {
    ...mapActions(['ShowNotification']),
    async getDepartment() {
      try {
        const response = await PrintAPI.get(
          `/getDepartment/${this.$route.params.id}`
        );
        this.department = response.data;
      } catch (error) {
        return error;
      }
    },
    async submitForm() {
      if (!this.department.name) {
        this.ShowNotification({
          type: 'warning',
          message: 'Please fill in required fields'
        });

        return;
      }
      try {
        await PrintAPI.patch(
          `/updateDepartment/${this.$route.params.id}`,
          this.department
        );

        this.ShowNotification({
          type: 'success',
          message: 'Department modified and saved successfully!'
        });
        this.$router.go(-1);
      } catch (error) {
        this.ShowNotification({
          type: 'error',
          message: 'Error updating department.'
        });
        return error;
      }
    },
    async deleteDepartment() {
      if (window.confirm('Are you sure you want to archive this department?')) {
        try {
          await PrintAPI.delete(`/deleteDepartment/${this.$route.params.id}`);

          this.ShowNotification({
            type: 'success',
            message: 'Department has been archived successfully!'
          });
          this.$router.go(-1);
        } catch (error) {
          this.ShowNotification({
            type: 'error',
            message: 'Error archiving department.'
          });
          return error;
        }
      }
    }
  }
});
