
import { defineComponent } from 'vue';
import { mapActions } from 'vuex';
import { PrintAPI } from '@/services/PrintAPIClient';
import { Job } from '@/services/jobs/types';

import AccentButton from '@/components/buttons/AccentButton.vue';
import JobForm from '@/modules/jobs/JobForm.vue';

export default defineComponent({
  name: 'EditJob',
  components: {
    AccentButton,
    JobForm
  },
  data() {
    return {
      showRequired: false,
      disableButtons: false //disable or enable buttons in the JobForm component
    };
  },
  methods: {
    ...mapActions(['ShowNotification']),
    async submitForm(jobData: Job) {
      try {
        this.disableButtons = true;
        const job = jobData;

        if (!job.departmentId) delete job.departmentId;
        if (!job.up) delete job.up;
        if (!job.datePrinted) delete job.datePrinted;
        if (!job.quantity) delete job.quantity;
        if (!job.x_x) delete job.x_x;
        if (!job.paperId) delete job.paperId;
        if (!job.cuts) delete job.cuts;
        if (job.print === null) delete job.print;
        if (job.postage === null) delete job.postage;
        if (job.cost === null) delete job.cost;

        if (!job.jobDescription || !job.companyId) {
          this.disableButtons = false;
          this.ShowNotification({
            type: 'warning',
            message: 'Please fill in all required fields'
          });
          this.showRequired = true;
          return;
        }

        await PrintAPI.put(`/editJob/${this.$route.params.id}`, job);

        this.ShowNotification({
          type: 'success',
          message: 'Job has been modified and saved successfully!'
        });
        this.disableButtons = false;
        this.$router.go(-1);
      } catch (error) {
        this.ShowNotification({
          type: 'error',
          message: 'Error updating job'
        });
        return error;
      }
    },
    async deleteJob() {
      if (window.confirm('Are you sure you want to archive this job?')) {
        try {
          await PrintAPI.delete(`/deleteJob/${this.$route.params.id}`);

          this.ShowNotification({
            type: 'success',
            message: 'Job archived successfully!'
          });
          this.$router.go(-1);
        } catch (error) {
          this.ShowNotification({
            type: 'error',
            message: 'Error archiving job.'
          });
          return error;
        }
      }
    }
  }
});
