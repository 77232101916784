<template>
  <div>
    <Header />
    <div class="flex">
      <Sidebar />
      <PagePanel @click="CloseDetailPanel">
        <router-view />
      </PagePanel>
      <NotificationBar />
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue';
import { mapActions } from 'vuex';

import Header from '@/global/Header.vue';
import Sidebar from '@/global/Sidebar.vue';
import PagePanel from '@/global/PagePanel.vue';
import NotificationBar from '@/global/NotificationBar.vue';

export default defineComponent({
  name: 'DashboardLayout',
  components: {
    Header,
    Sidebar,
    PagePanel,
    NotificationBar
  },
  methods: {
    ...mapActions(['CloseDetailPanel'])
  }
});
</script>

<style></style>
