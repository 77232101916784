<template>
  <div class="flex flex-col w-full">
    <h1 class="text-3xl font-bold mt-20">404 Not Found</h1>
    <div class="flex flex-col align-center items-center w-full">
      <img src="@/assets/images/not_found.jpg" />
      <button class="bg-bright_blue rounded-2xl text-white w-80 h-20 text-xl">
        <router-link to="/"> Take me to home</router-link>
      </button>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style></style>
