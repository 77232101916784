import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_DashboardLayout = _resolveComponent("DashboardLayout")!
  const _component_LoginLayout = _resolveComponent("LoginLayout")!

  return (_ctx.GetAuth)
    ? (_openBlock(), _createBlock("div", _hoisted_1, [
        _createVNode(_component_DashboardLayout)
      ]))
    : (_openBlock(), _createBlock("div", _hoisted_2, [
        _createVNode(_component_LoginLayout)
      ]))
}