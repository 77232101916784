import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';
import 'vue-router';
import store from '../store/index';
import { checkAuth } from '@/services/utils-auth';

declare module 'vue-router' {
  interface RouteMeta {
    userRole?: string /**USER, ADMIN, GLOBAL_ADMIN */;
  }
}

const routes: Array<RouteRecordRaw> = [
  /**PUBLIC ROUTES */
  {
    path: '/login',
    name: 'Login',
    component: () => import('@/views/authentication/Login.vue')
  },
  {
    path: '/forgot-password',
    name: 'ForgotPassword',
    component: () => import('@/views/authentication/ForgotPassword.vue')
  },
  {
    path: '/reset-password/:token/:id',
    name: 'ResetPassword',
    component: () => import('@/views/authentication/ExternalPasswordReset.vue')
  },
  /**END PUBLIC ROUTES */
  /**DASHBOARD AND JOB MANAGEMENT ROUTES */
  {
    path: '/',
    name: 'Home',
    component: () => import('@/views/Home.vue')
  },
  {
    path: '/add-job',
    name: 'AddJob',
    component: () => import('@/views/jobs/AddJob.vue')
  },
  {
    path: '/edit-job/:id',
    name: 'EditJob',
    component: () => import('@/views/jobs/EditJob.vue')
  },
  {
    path: '/manage-jobs',
    name: 'JobHistory',
    component: () => import('@/views/jobs/ManageJobs.vue')
  },
  /**END DASHBOARD AND JOB MANAGEMENT ROUTES */
  /**PURCHASE ORDER ROUTES */
  {
    path: '/purchase-orders',
    name: 'PurchaseOrders',
    component: () => import('@/views/purchase-orders/PurchaseOrders.vue')
  },
  {
    path: '/edit-purchase-order/:id',
    name: 'EditPurchaseOrder',
    component: () => import('@/views/purchase-orders/EditPurchaseOrder.vue')
  },
  /**END PURCHASE ORDER ROUTES */
  /**USER MANAGEMENT ROUTES */
  {
    path: '/view-hours',
    name: 'ViewHours',
    component: () => import('@/views/timeclock/AdminViewHours.vue'),
    meta: {
      userRole: 'ADMIN'
    }
  },
  {
    path: '/view-hours/history',
    name: 'ViewHoursHistory',
    component: () => import('@/views/timeclock/TimeclockHistoryDownload.vue'),
    meta: {
      userRole: 'ADMIN'
    }
  },
  {
    path: '/edit-hours/:id',
    name: 'EditHours',
    component: () => import('@/views/timeclock/AdminViewUserHours.vue'),
    meta: {
      userRole: 'ADMIN'
    }
  },
  {
    path: '/add-hours',
    name: 'AddHours',
    component: () => import('@/views/timeclock/AdminAddHours.vue'),
    meta: {
      userRole: 'ADMIN'
    }
  },
  // {
  //   path: '/view-my-hours',
  //   name: 'UserViewHours',
  //   component: () => import('@/views/timeclock/UserViewHours.vue')
  // },

  /**END TIMECLOCK MANAGEMENT ROUTES */
  /**USER MANAGEMENT ROUTES */
  {
    path: '/manage-users',
    name: 'ManageUser',
    component: () => import('@/views/users/ManageUsers.vue'),
    meta: {
      userRole: 'ADMIN'
    }
  },
  {
    path: '/add-user',
    name: 'AddUser',
    component: () => import('@/views/users/AddUser.vue'),
    meta: {
      userRole: 'ADMIN'
    }
  },
  {
    path: '/edit-user/:id',
    name: 'EditUser',
    component: () => import('@/views/users/EditUser.vue'),
    meta: {
      userRole: 'ADMIN'
    }
  },
  {
    path: '/edit-profile/:id',
    name: 'EditProfile',
    component: () => import('@/views/users/EditUser.vue')
  },
  /**END USER MANAGEMENT ROUTES */
  /**INVENTORY MANAGEMENT ROUTES */
  {
    path: '/inventory',
    name: 'Inventory',
    component: () => import('@/views/inventory/Inventory.vue')
  },
  {
    path: '/add-inventory',
    name: 'AddInventory',
    component: () => import('@/views/inventory/AddInventory.vue')
  },
  {
    path: '/edit-inventory/:id',
    name: 'EditInventory',
    component: () => import('@/views/inventory/EditInventory.vue')
  },
  /**END INVENTORY MANAGEMENT ROUTES */
  /**COMPANY MANAGEMENT ROUTES */
  {
    path: '/manage-companies',
    name: 'ManageCompany',
    component: () => import('@/views/companies/ManageCompanies.vue')
  },
  {
    path: '/add-company',
    name: 'AddCompany',
    component: () => import('@/views/companies/AddCompany.vue')
  },
  {
    path: '/edit-company/:id',
    name: 'EditCompany',
    component: () => import('@/views/companies/EditCompany.vue')
  },
  /**END COMPANY MANAGEMENT ROUTES */
  /**START DEPARTMENT MANAGEMENT ROUTES */
  {
    path: '/manage-departments',
    name: 'ManageDepartment',
    component: () => import('@/views/departments/ManageDepartments.vue')
  },
  {
    path: '/add-department',
    name: 'AddDepartment',
    component: () => import('@/views/departments/AddDepartment.vue')
  },
  {
    path: '/edit-department/:id',
    name: 'EditDepartment',
    component: () => import('@/views/departments/EditDepartment.vue')
  },
  /**END DEPARTMENT MANAGEMENT ROUTES */
  /**START PAPER PRICING MANAGEMENT ROUTES */
  {
    path: '/manage-pricing',
    name: 'ManagePricing',
    component: () => import('@/views/pricing/ManagePricing.vue')
  },
  {
    path: '/manage-pricing/edit-paper-table',
    name: 'EditPaperTable',
    component: () => import('@/views/pricing/EditPaperTable.vue')
  },
  {
    path: '/add-paper',
    name: 'AddPaper',
    component: () => import('@/views/pricing/AddPaper.vue')
  },
  {
    path: '/edit-paper/:id',
    name: 'ManagePaper',
    component: () => import('@/views/pricing/EditPaper.vue')
  },
  /**END PAPER PRICING MANAGEMENT ROUTES */
  /**START MAILER PRICE CALCULATOR ROUTES */
  {
    path: '/prices',
    name: 'PriceCalculator',
    component: () => import('@/views/Calculator.vue')
  },
  /**END MAILER PRICE CALCULATOR ROUTES */
  /**CATCH ALL/404 ROUTE */
  {
    path: '/:pathMatch(.*)*',
    name: 'NotFound',
    component: () => import('@/views/404.vue')
  }
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
});

router.beforeEach((to, from, next) => {
  checkAuth();

  const publicRoutes = ['Login', 'ForgotPassword', 'ResetPassword'];
  const currentRouteIsPublic = publicRoutes.includes(String(to.name)); // requires es2016

  const currentUserAuth = store.getters.GetAuth;
  const currentUserRole = store.getters.GetRole;

  if (!currentRouteIsPublic && !currentUserAuth) {
    next({ name: 'Login' });
  } else if (to.meta.userRole == 'ADMIN' && currentUserRole !== 'ADMIN') {
    next({ name: 'Home' });
  } else if (currentRouteIsPublic && currentUserAuth) {
    // ensure a logged in (authenticated) user doesn't navigate to public routes
    next({ name: 'Home' });
  } else {
    next();
  }
});

export default router;
