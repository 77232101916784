import { PrintAPI } from '../PrintAPIClient';
import {
  InventoryItem,
  InventoryTableReturn,
  InventoryDetailPanelReturn,
  InventoryType
} from './types';

const handleInventoryData = async (
  inventory: InventoryItem[]
): Promise<InventoryItem[]> => {
  try {
    const types = await PrintAPI.get('/inventoryTypes');

    const inventoryInfoArray = inventory.map((item: InventoryItem) => {
      const typeId = item.typeId;
      let typeName = '';
      types.data.forEach((type: InventoryType) => {
        if (typeId === type.id) {
          typeName = type.name;
        }
      });

      const value = item.quantity * item.itemCostPerUnit;
      const newValue = value.toFixed(2);

      const totalValue = `$${newValue}`;

      const inventoryInfo = {
        ...item,
        typeName,
        totalValue
      };

      return inventoryInfo;
    });

    return inventoryInfoArray;
  } catch (error: any) {
    return error;
  }
};

export const fetchTableInventory = async (): Promise<InventoryTableReturn> => {
  try {
    const allInventory = await PrintAPI.get('/inventoryItems');
    const inventoryInfoArray = await handleInventoryData(allInventory.data);

    return {
      inventory: inventoryInfoArray,
      inventoryProperties: [
        'name',
        'typeName',
        'quantity',
        'itemCostPerUnit',
        'totalValue',
        'notes'
      ],
      inventoryTableHeadProperties: [
        'Item Name',
        'Item Type',
        'Quantity',
        'Item Cost Per Unit',
        'Total Value on Hand',
        'Notes'
      ]
    };
  } catch (error: any) {
    return error;
  }
};

export const getDetailPanelInventory = async (
  id: number
): Promise<InventoryDetailPanelReturn> => {
  try {
    const inventoryItem = await PrintAPI.get(`/getInventoryItem/${id}`);
    const itemArray = [inventoryItem.data];
    const inventoryInfoArray = await handleInventoryData(itemArray);
    const itemData = inventoryInfoArray[0];

    const detailPanelData = {
      resourceId: id,
      headerData: {
        key1: 'Inventory',
        key2: `${itemData.name}`
      },
      bodyData: {
        'Item Name': itemData.name,
        'Item Type': itemData.typeName,
        Quantity: itemData.quantity,
        'Item Cost Per Unit': `$${itemData.itemCostPerUnit}`,
        'Total Value on Hand': itemData.totalValue,
        Notes: itemData.notes
      }
    };

    return { detailPanelData, deleted: itemData.deletedAt };
  } catch (error: any) {
    return error;
  }
};
