import { PrintAPI } from '../PrintAPIClient';
import { Job, Company, Department, Paper, JobCalculatorInput } from './types';
import { ConvertDate } from '../utils-times';

export interface getJobReturn {
  job: Job;
  finishedSize: string;
}

export const getJob = async (id: number): Promise<getJobReturn> => {
  const response = await PrintAPI.get(`/getJob/${id}`);
  const { datePrinted, complete } = response.data.job;

  if (datePrinted) {
    response.data.job.datePrinted = response.data.job.datePrinted.substr(0, 10);
  }

  if (complete) {
    response.data.job.complete = ConvertDate(response.data.job.complete);
  }

  let finishedSize = '';
  if (response.data.paper) {
    finishedSize = response.data.paper.paperSize;
  }

  return {
    job: response.data.job,
    finishedSize: finishedSize
  };
};

export const getCompanies = async (): Promise<Company[]> => {
  const response = await PrintAPI.get('/companies');

  return response.data.filter((item: Company) => item.deletedAt === null);
};

export const getDepartments = async (): Promise<Department[]> => {
  const response = await PrintAPI.get('/departments');

  return response.data.filter((item: Department) => item.deletedAt === null);
};

export const getPaper = async () => {
  const response = await PrintAPI.get('/papers');

  const paperOptions = response.data.filter(
    (item: Paper) => item.deletedAt === null
  );

  const allPaperSizes = paperOptions.map((paper: Paper) => paper.paperSize);
  const paperSizeSet = new Set(allPaperSizes);
  const paperSizes = Array.from(paperSizeSet);
  const paperSizeOptions = paperSizes as string[];

  return {
    paper: paperOptions,
    paperSizeOptions: paperSizeOptions
  };
};
