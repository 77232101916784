
import { defineComponent } from 'vue';
import { mapActions } from 'vuex';

import PrimaryButton from '@/components/buttons/PrimaryButton.vue';
import TertiaryButton from '@/components/buttons/TertiaryButton.vue';
import AccentButton from '@/components/buttons/AccentButton.vue';

export default defineComponent({
  name: 'Detail',
  components: {
    PrimaryButton,
    TertiaryButton,
    AccentButton
  },
  props: {
    panelData: {
      type: Object,
      default() {
        return {
          resourceId: 0,
          headerData: {
            key1: '',
            key2: ''
          },
          bodyData: {}
        };
      }
    },
    panelBtns: {
      type: Array,
      default() {
        return [];
      }
    },
    handlePrimaryBtn: {
      type: Function,
      default: () => false
    },
    handleTertiaryBtn: {
      type: Function,
      default: () => false
    },
    handleAccentBtn: {
      type: Function,
      default: () => false
    }
  },
  methods: {
    ...mapActions(['CloseDetailPanel'])
  }
});
