
import { defineComponent } from 'vue';
import { mapActions } from 'vuex';
import { PrintAPI } from '@/services/PrintAPIClient';

import Input from '@/components/forms/Input.vue';
import AccentButton from '@/components/buttons/AccentButton.vue';
import TertiaryButton from '@/components/buttons/TertiaryButton.vue';
import PrimaryButton from '@/components/buttons/PrimaryButton.vue';

export default defineComponent({
  name: 'AddDepartment',
  components: {
    Input,
    AccentButton,
    TertiaryButton,
    PrimaryButton
  },
  data() {
    return {
      department: {
        name: ''
      }
    };
  },
  methods: {
    ...mapActions(['ShowNotification']),
    async submitForm(mode: string) {
      if (!this.department.name) {
        this.ShowNotification({
          type: 'warning',
          message: 'Please fill in required fields'
        });

        return;
      }
      try {
        await PrintAPI.post('/createDepartment', this.department);
        this.ShowNotification({
          type: 'success',
          message: 'Department created successfully!'
        });
        if (mode === 'save') this.$router.go(-1);
        if (mode === 'add') this.$router.go(0);
      } catch (error) {
        this.ShowNotification({
          type: 'error',
          warning: 'Error creating department.'
        });
        return error;
      }
    }
  }
});
