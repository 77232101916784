
import { defineComponent } from 'vue';
export default defineComponent({
  name: 'Checkbox',
  props: {
    label: { type: String, default: '' },
    name: { type: String, default: '' },
    required: { type: Boolean, default: false },
    checked: { type: Boolean },
    readonly: { type: Boolean, default: false }
  },
  emits: ['update:checked'],
  methods: {
    onInput(event: Event) {
      const target = event.target as HTMLInputElement;
      this.$emit('update:checked', target.checked);
    }
  }
});
