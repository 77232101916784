
import { defineComponent } from 'vue';
import { PrintAPI } from '@/services/PrintAPIClient';
import { mapActions, mapGetters } from 'vuex';
import { InventoryItem } from '@/services/inventory/types';
import { Paper } from '@/services/jobs/types';

import PrimaryButton from '@/components/buttons/PrimaryButton.vue';
import AccentButton from '@/components/buttons/AccentButton.vue';
import Input from '@/components/forms/Input.vue';

export default defineComponent({
  name: 'EditPaper',
  components: {
    PrimaryButton,
    AccentButton,
    Input
  },
  data() {
    return {
      paper: {} as Paper
    };
  },
  created() {
    this.getPaper();
  },
  methods: {
    ...mapActions(['ShowNotification']),
    async getPaper() {
      const response = await PrintAPI.get(`/paper/${this.$route.params.id}`);
      this.paper = response.data;
    },
    async submitForm() {
      const {
        paperSize,
        paperName,
        cost10,
        cost11,
        cost40,
        cost44,
        retail10,
        retail11,
        retail40,
        retail44,
        cutFee
      } = this.paper;
      if (
        !paperSize ||
        !paperName ||
        !cost10 ||
        !cost11 ||
        !cost40 ||
        !cost44 ||
        !retail10 ||
        !retail11 ||
        !retail40 ||
        !retail44 ||
        !cutFee
      ) {
        this.ShowNotification({
          type: 'warning',
          message: 'Please fill in required fields'
        });
        return;
      }
      try {
        await PrintAPI.put(`/updatePaper/${this.$route.params.id}`, this.paper);
        this.ShowNotification({
          type: 'success',
          message: 'Paper has been modified and saved successfully!'
        });
        this.$router.go(-1);
      } catch (error) {
        this.ShowNotification({
          type: 'error',
          message: 'Error updating paper'
        });
        return error;
      }
    },
    async deletePaper() {
      if (
        window.confirm('Are you sure you want to archive this paper pricing?')
      ) {
        try {
          await PrintAPI.delete(`/deletePaper/${this.$route.params.id}`);
          this.ShowNotification({
            type: 'success',
            message: 'Paper archived successfully!'
          });
          this.$router.go(-1);
        } catch (error) {
          this.ShowNotification({
            type: 'error',
            message: 'Error archiving paper'
          });
          return error;
        }
      }
    }
  }
});
