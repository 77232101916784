import axios from 'axios';
import { checkAuth } from './utils-auth';

const PrintAPI = axios.create({
  baseURL: process.env.VUE_APP_API_BASE_URL
});

PrintAPI.defaults.withCredentials = true;

PrintAPI.interceptors.request.use(function (config) {
  checkAuth();

  return config;
});

export { PrintAPI };
