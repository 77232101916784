
import { defineComponent } from 'vue';
import { mapActions } from 'vuex';

import Input from '@/components/forms/Input.vue';
import PrimaryButton from '@/components/buttons/PrimaryButton.vue';

export default defineComponent({
  name: 'Login',
  components: {
    Input,
    PrimaryButton
  },
  data() {
    return {
      user: {
        username: '',
        password: ''
      }
    };
  },
  methods: {
    ...mapActions(['LoginUser', 'ShowNotification']),
    async submitForm() {
      try {
        await this.LoginUser(this.user);
        this.$router.push('/');
      } catch (e: any) {
        this.ShowNotification({
          type: 'error',
          message: e.response.data.message
        });
      }
    }
  }
});
