
import { defineComponent } from 'vue';
import { mapActions } from 'vuex';
import { PrintAPI } from '@/services/PrintAPIClient';
import { Job } from '@/services/jobs/types';

import PrimaryButton from '@/components/buttons/PrimaryButton.vue';
import JobForm from '@/modules/jobs/JobForm.vue';

export default defineComponent({
  name: 'AddJob',
  components: {
    PrimaryButton,
    JobForm
  },
  data() {
    return {
      showRequired: false,
      disableButtons: false //disable or enable buttons in the JobForm component
    };
  },
  methods: {
    ...mapActions(['ShowNotification']),
    validateInput(jobData: Job, submitMode: string) {
      this.disableButtons = true;

      let job = jobData;

      //default datePrinted to date and time of job creation
      job.datePrinted = new Date().toISOString();

      if (!job.departmentId) delete job.departmentId;
      if (!job.up) delete job.up;
      if (!job.quantity) delete job.quantity;
      if (!job.x_x) delete job.x_x;
      if (!job.paperId) delete job.paperId;
      if (!job.cuts) delete job.cuts;
      if (!job.print) delete job.print;
      if (!job.postage) delete job.postage;
      if (!job.cost) delete job.cost;
      if (job.complete) {
        job.complete = new Date().toISOString();
      }

      if (!job.jobDescription || !job.companyId) {
        this.disableButtons = false;

        this.ShowNotification({
          type: 'warning',
          message: 'Please fill in all required fields'
        });
        this.showRequired = true;
        return;
      }
      this.showRequired = false;
      this.submitForm(submitMode, job);
    },
    async submitForm(mode: string, data: any) {
      try {
        await PrintAPI.post('/createJob', data);
        this.ShowNotification({
          type: 'success',
          message: 'Job created successfully!'
        });

        this.disableButtons = false;

        if (mode === 'save') this.$router.push('/');
        if (mode === 'add') this.$router.go(0);
      } catch (error) {
        this.ShowNotification({
          type: 'error',
          message: `Error creating job: ${error}`
        });
        return error;
      }
    }
  }
});
