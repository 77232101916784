
import { defineComponent } from 'vue';
import { mapActions } from 'vuex';
import { PrintAPI } from '@/services/PrintAPIClient';

import Input from '@/components/forms/Input.vue';
import Select from '@/components/forms/Select.vue';
import Checkbox from '@/components/forms/Checkbox.vue';
import AccentButton from '@/components/buttons/AccentButton.vue';
import TertiaryButton from '@/components/buttons/TertiaryButton.vue';
import PrimaryButton from '@/components/buttons/PrimaryButton.vue';

export default defineComponent({
  name: 'AddUser',
  components: {
    Input,
    Select,
    Checkbox,
    AccentButton,
    TertiaryButton,
    PrimaryButton
  },
  data() {
    return {
      user: {
        firstName: '',
        lastName: '',
        accountName: '',
        username: '',
        email: '',
        password: '',
        role: '',
        hourly: true
      },
      passwordAssert: ''
    };
  },
  methods: {
    ...mapActions(['ShowNotification']),
    validateRequiredFields() {
      // requires es2017
      for (const [key, value] of Object.entries(this.user)) {
        if (!value) {
          // all user values are required (cannot be empty strings)
          if (key === 'hourly') {
            return true;
          } else {
            return false;
          }
        }
      }
      return true;
    },
    async submitForm(mode: string) {
      try {
        const validInputs = this.validateRequiredFields();
        if (!validInputs) {
          this.ShowNotification({
            type: 'warning',
            message: 'Please fill in all required fields'
          });
          return;
        }

        if (this.passwordAssert !== this.user.password) {
          this.ShowNotification({
            type: 'warning',
            message: 'Passwords must match'
          });
          return;
        }
        await PrintAPI.post('/registerUser', this.user);
        this.ShowNotification({
          type: 'success',
          message: 'User created successfully!'
        });
        if (mode === 'save') this.$router.go(-1);
        if (mode === 'add') this.$router.go(0);
      } catch (e) {
        this.ShowNotification({
          type: 'error',
          message: 'Error creating user.'
        });
      }
    }
  }
});
