import { toDisplayString as _toDisplayString, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "flex flex-row" }
const _hoisted_2 = {
  for: "hourly",
  class: "font-bold text-grey-darker block my-4"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createBlock("div", _hoisted_1, [
    _createVNode("label", _hoisted_2, _toDisplayString(_ctx.label), 1),
    _createVNode("input", {
      class: ["block px-2 py-2 self-center ml-4", _ctx.readonly ? 'cursor-not-allowed' : ''],
      type: "checkbox",
      name: _ctx.name,
      required: _ctx.required,
      checked: _ctx.checked,
      disabled: _ctx.readonly,
      onInput: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.onInput && _ctx.onInput(...args)))
    }, null, 42, ["name", "required", "checked", "disabled"])
  ]))
}