
import { defineComponent } from 'vue';
import { mapActions, mapState } from 'vuex';
import { RootState } from '@/store/types';

export default defineComponent({
  name: 'HeaderAccountControls',
  props: {
    closeAccountControls: { type: Function, default: () => false }
  },
  computed: mapState<RootState>({
    userId: (state: RootState) => state.user.id
  }),
  methods: {
    ...mapActions(['LogoutUser']),
    redirect(id: number) {
      // redirect to edit page
      this.$router.push(`/edit-profile/${id}`);
      // close account controls
      this.$props.closeAccountControls();
    }
  }
});
