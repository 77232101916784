<script>
import { defineComponent } from 'vue';
import { Pie } from 'vue3-chart-v2';

export default defineComponent({
  name: 'SalesCostChart',
  extends: Pie,
  props: {
    data: {
      type: Array,
      default() {
        return [];
      }
    }
  },
  data() {
    return {
      numbers: this.data
    };
  },
  watch: {
    data: {
      handler(newprop) {
        this.numbers = newprop;
        this.chart();//render the chart whenever data changes
      },
      deep: true
    }
  },
  mounted() {
    // getting color for gradient
    this.number = this.data;
    this.gradient = this.$refs.canvas
      .getContext('2d')
      .createLinearGradient(0, 0, 0, 450);
    this.gradient2 = this.$refs.canvas
      .getContext('2d')
      .createLinearGradient(0, 0, 0, 450);

    this.gradient.addColorStop(0, 'rgba(255, 0,0, 0.5)');
    this.gradient.addColorStop(0.5, 'rgba(255, 0, 0, 0.25)');

    this.gradient2.addColorStop(0, 'rgba(0, 231, 255, 0.9)');
    this.gradient2.addColorStop(0.5, 'rgba(0, 231, 255, 0.25)');

    this.chart();
  },
  methods: {
    chart() {
      if (!this.number) {
        this.number = [0, 0]; //first number: sales, second number:cost
      }
      this.renderChart(
        {
          labels: ['Sales', 'Costs'],
          datasets: [
            {
              backgroundColor: [this.gradient2, this.gradient],
              data: this.numbers
            }
          ]
        },
        { responsive: true, maintainAspectRatio: false }
      );
    }
  }
});
</script>
