
import { defineComponent } from 'vue';
import { mapActions, mapGetters } from 'vuex';
import { PrintAPI } from '@/services/PrintAPIClient';
import { ConvertDate } from '@/services/utils-times';
import { Company } from '@/services/jobs/types';
import { PanelBtn } from '@/store/types';
import { Paper } from '@/services/jobs/types';

import SearchBar from '@/components/searches/SearchBar.vue';
import PrimaryButton from '@/components/buttons/PrimaryButton.vue';
import SecondaryButton from '@/components/buttons/SecondaryButton.vue';
import Table from '@/components/tables/Table.vue';
import TableDetail from '@/components/tables/TableDetail.vue';
import Loading from 'vue-loading-overlay';

export default defineComponent({
  name: 'ManageCompany',
  components: {
    SearchBar,
    PrimaryButton,
    SecondaryButton,
    Table,
    TableDetail,
    Loading
  },
  data() {
    return {
      isLoading: false,
      noData: false,
      papers: [] as Paper[],
      paperProperties: [
        'paperSize',
        'paperName',
        'cost10',
        'cost11',
        'cost40',
        'cost44',
        'retail10',
        'retail11',
        'retail40',
        'retail44',
        'cutFee'
      ],
      paperTableHeadProperties: [
        'Paper Size',
        'Paper Name',
        'P&M Cost 1/0',
        'P&M Cost 1/1',
        'P&M Cost 4/0',
        'P&M Cost 4/4',
        'Customer Cost 1/0',
        'Customer Cost 1/1',
        'Customer Cost 4/0',
        'Customer Cost 4/4',
        'Cut Fee'
      ],
      timer: null as number | null,
      debouncedSearchInput: '',
      active: true,
      panelData: {},
      panelBtns: [] as PanelBtn[]
    };
  },
  computed: {
    ...mapGetters(['GetDetailPanelIsOpen']),
    filterPapers(): Paper[] {
      let returnPapers = this.papers;
      let filteredPapers = this.papers;

      if (this.active) {
        filteredPapers = filteredPapers.filter(
          (paper: Paper) => paper.deletedAt === null
        );
      } else {
        filteredPapers = filteredPapers.filter(
          (paper: Paper) => paper.deletedAt != null
        );
      }

      returnPapers = filteredPapers;

      if (this.debouncedSearchInput != '' && this.debouncedSearchInput) {
        returnPapers = this.papers.filter((item: Paper) => {
          return this.debouncedSearchInput
            .toLowerCase()
            .split(' ')
            .every((v) => JSON.stringify(item).toLowerCase().includes(v));
        });
      }

      return returnPapers;
    },
    searchInput: {
      get(): string {
        return this.debouncedSearchInput;
      },
      set(val: string) {
        if (this.timer) clearTimeout(this.timer);
        this.timer = setTimeout(() => {
          this.debouncedSearchInput = val;
        }, 500);
      }
    }
  },
  created() {
    this.fetchPapers();
  },
  methods: {
    ...mapActions(['OpenDetailPanel', 'CloseDetailPanel', 'ShowNotification']),
    async fetchPapers() {
      try {
        this.isLoading = true;
        const response = await PrintAPI.get('/papers');
        this.papers = response.data;
        this.isLoading = false;
      } catch (e) {
        return e;
      }
    },
    async fetchPaper(id: number): Promise<Paper> {
      try {
        const response = await PrintAPI.get(`/paper/${id}`);
        return response.data;
      } catch (e: any) {
        return e;
      }
    },
    async openTableDetail(id: number) {
      try {
        const data = await this.fetchPaper(id);

        this.panelData = {
          resourceId: id,
          headerData: {
            key1: 'Paper & Pricing',
            key2: `${data.paperName}`
          },
          bodyData: {
            'Paper Name': data.paperName,
            'Paper Size': data.paperSize,
            'Cost 1/0': `$${data.cost10}`,
            'Cost 1/1': `$${data.cost11}`,
            'Cost 4/0': `$${data.cost40}`,
            'Cost 4/4': `$${data.cost44}`,
            'Cut Fee': `$${data.cutFee}`
          }
        };

        this.panelBtns = [{ type: 'primary', label: 'Edit' }];

        if (data.deletedAt) {
          const tertiaryBtn = { type: 'tertiary', label: 'Recover' };
          this.panelBtns.unshift(tertiaryBtn);
        } else {
          const accentBtn = { type: 'accent', label: 'Archive' };
          this.panelBtns.unshift(accentBtn);
        }

        this.OpenDetailPanel();
      } catch (e) {
        return e;
      }
    },
    handlePrimaryBtn(id: number) {
      this.$router.push(`/edit-paper/${id}`);
      this.CloseDetailPanel();
    },
    async deletePaper(id: number) {
      if (window.confirm('Are you sure you want to archive this paper?')) {
        try {
          await PrintAPI.delete(`/deletePaper/${id}`);
          this.ShowNotification({
            type: 'success',
            message: 'Paper successfully archived!'
          });
          this.fetchPapers();
        } catch (e) {
          this.ShowNotification({
            type: 'error',
            message: 'Error archiving paper.'
          });
          return e;
        }
      }
    },
    async recoverPaper(id: number) {
      try {
        await PrintAPI.put(`/recoverPaper/${id}`);
        this.ShowNotification({
          type: 'success',
          message: 'Paper restored successfully!'
        });
        this.fetchPapers();
      } catch (e) {
        this.ShowNotification({
          type: 'error',
          message: 'Error restoring paper.'
        });
        return e;
      }
    }
  }
});
