
import { defineComponent } from 'vue';
import { mapActions, mapGetters } from 'vuex';
import { PrintAPI } from '@/services/PrintAPIClient';
import {
  fetchTableUsers,
  getDetailPanelUser
} from '@/services/users/users-data';
import { User } from '@/services/users/types';
import { PanelBtn } from '@/store/types';

import PrimaryButton from '@/components/buttons/PrimaryButton.vue';
import SearchBar from '@/components/searches/SearchBar.vue';
import Table from '@/components/tables/Table.vue';
import TableDetail from '@/components/tables/TableDetail.vue';
import Loading from 'vue-loading-overlay';

export default defineComponent({
  name: 'ManageUser',
  components: {
    PrimaryButton,
    SearchBar,
    Table,
    TableDetail,
    Loading
  },
  data() {
    return {
      isLoading: false,
      users: [] as User[],
      userProperties: [] as string[],
      userTableHeadProperties: [] as string[],
      timer: null as number | null,
      debouncedSearchInput: '',
      active: true,
      panelData: {},
      panelBtns: [] as PanelBtn[]
    };
  },
  computed: {
    ...mapGetters(['GetDetailPanelIsOpen']),
    filterUsers(): User[] {
      let filteredUsers = this.users;
      let returnUsers = this.users;

      if (this.active) {
        filteredUsers = this.users.filter(
          (user: User) => !user.deletedAt || user.deletedAt === null
        );
      } else {
        filteredUsers = this.users.filter(
          (user: User) => user.deletedAt != null
        );
      }

      returnUsers = filteredUsers;

      if (this.debouncedSearchInput != '' && this.debouncedSearchInput) {
        returnUsers = filteredUsers.filter((item: User) => {
          return this.debouncedSearchInput
            .toLowerCase()
            .split(' ')
            .every((v) => JSON.stringify(item).toLowerCase().includes(v));
        });
      }

      return returnUsers;
    },
    searchInput: {
      get(): string {
        return this.debouncedSearchInput;
      },
      set(val: string) {
        if (this.timer) clearTimeout(this.timer);
        this.timer = setTimeout(() => {
          this.debouncedSearchInput = val;
        }, 500);
      }
    }
  },
  created() {
    this.fetchUsers();
  },
  methods: {
    ...mapActions(['OpenDetailPanel', 'CloseDetailPanel', 'ShowNotification']),
    async fetchUsers() {
      try {
        this.isLoading = true;
        const response = await fetchTableUsers();
        this.users = response.users;
        this.userProperties = response.userProperties;
        this.userTableHeadProperties = response.userTableHeadProperties;
        this.isLoading = false;
      } catch (e) {
        return e;
      }
    },
    async openTableDetail(id: number) {
      try {
        const response = await getDetailPanelUser(id);

        this.panelData = response;

        this.panelBtns = [{ type: 'primary', label: 'Edit' }];

        if (response.bodyData.Archived === 'N/A') {
          const accentBtn = { type: 'accent', label: 'Archive' };
          this.panelBtns.unshift(accentBtn);
        } else {
          const tertiaryBtn = { type: 'tertiary', label: 'Restore' };
          this.panelBtns.unshift(tertiaryBtn);
        }
        this.OpenDetailPanel();
      } catch (e) {
        return e;
      }
    },
    handlePrimaryBtn(id: number) {
      this.$router.push(`/edit-user/${id}`);
      this.CloseDetailPanel();
    },
    async archiveUser(id: number) {
      if (window.confirm('Are you sure you want to archive this user?')) {
        try {
          await PrintAPI.delete(`/deleteUser/${id}`);
          this.ShowNotification({
            type: 'success',
            message: 'User archived successfully!'
          });
          this.fetchUsers();
        } catch (e) {
          this.ShowNotification({
            type: 'error',
            message: 'Error archiving user'
          });
          return e;
        }
      }
    },
    async recoverUser(id: number) {
      try {
        await PrintAPI.put(`/recoverUser/${id}`);
        this.ShowNotification({
          type: 'success',
          message: 'User restored successfully'
        });
        this.fetchUsers();
      } catch (e) {
        this.ShowNotification({
          type: 'error',
          message: 'Error restoring user'
        });
        return e;
      }
    }
  }
});
