
import { defineComponent } from 'vue';
import { mapActions, mapGetters } from 'vuex';
import { PrintAPI } from '@/services/PrintAPIClient';
import { Company } from '@/services/jobs/types';
import { PO, CreatePO } from '@/services/purchase-orders/types';
import { PanelBtn } from '@/store/types';
import {
  fetchTablePOs,
  getDetailPanelPO
} from '@/services/purchase-orders/po-data';

import Select from '@/components/forms/Select.vue';
import Input from '@/components/forms/Input.vue';
import PrimaryButton from '@/components/buttons/PrimaryButton.vue';
import Table from '@/components/tables/Table.vue';
import TableDetail from '@/components/tables/TableDetail.vue';
import SearchBar from '@/components/searches/SearchBar.vue';
import Loading from 'vue-loading-overlay';

export default defineComponent({
  name: 'PurchaseOrders',
  components: {
    Select,
    Input,
    PrimaryButton,
    Table,
    TableDetail,
    SearchBar,
    Loading
  },
  data() {
    return {
      isLoading: false,
      companies: [] as Company[],
      purchaseOrders: [] as PO[],
      poProperties: [] as string[],
      poTableHeadProperties: [] as string[],
      newPO: {
        userId: null
      } as CreatePO,
      active: true,
      timer: null as number | null,
      debouncedSearchInput: '',
      panelData: {},
      panelBtns: [] as PanelBtn[]
    };
  },
  computed: {
    ...mapGetters(['GetCurrentUser', 'GetDetailPanelIsOpen']),
    filterPOs(): PO[] {
      let returnPOs = this.purchaseOrders;
      let filteredPOs = this.purchaseOrders;

      if (this.active) {
        filteredPOs = filteredPOs.filter((PO: PO) => PO.deletedAt === null);
      } else {
        filteredPOs = filteredPOs.filter((PO: PO) => PO.deletedAt != null);
      }

      returnPOs = filteredPOs;

      if (this.debouncedSearchInput != '' && this.debouncedSearchInput) {
        returnPOs = this.purchaseOrders.filter((item: PO) => {
          return this.debouncedSearchInput
            .toLowerCase()
            .split(' ')
            .every((v) => JSON.stringify(item).toLowerCase().includes(v));
        });
      }

      return returnPOs;
    },
    searchInput: {
      get(): string {
        return this.debouncedSearchInput;
      },
      set(val: string) {
        if (this.timer) clearTimeout(this.timer);
        this.timer = setTimeout(() => {
          this.debouncedSearchInput = val;
        }, 500);
      }
    }
  },
  created() {
    this.getCompanies();
    this.fetchPOs();
    this.newPO.userId = this.GetCurrentUser.id;
  },
  methods: {
    ...mapActions(['OpenDetailPanel', 'CloseDetailPanel', 'ShowNotification']),
    async getCompanies() {
      const response = await PrintAPI.get('/companies');
      this.companies = response.data.filter(
        (item: Company) => item.deletedAt === null
      );
    },
    async fetchPOs() {
      try {
        this.isLoading = true;
        const response = await fetchTablePOs();

        this.purchaseOrders = response.pos;
        this.poProperties = response.poProperties;
        this.poTableHeadProperties = response.poTableHeadProperties;
        this.isLoading = false;
      } catch (error) {
        return error;
      }
    },
    async submitForm() {
      try {
        await PrintAPI.post('/createPO', this.newPO);
        this.ShowNotification({
          type: 'success',
          message: 'Purchase Order created successfully!'
        });
        this.fetchPOs();
      } catch (error) {
        this.ShowNotification({
          type: 'error',
          message: 'Error creating purchase order.'
        });
        return error;
      }
    },
    async openTableDetail(id: number) {
      const response = await getDetailPanelPO(id);

      this.panelData = {
        ...response.detailPanelData,
        resourceId: response.detailPanelData.resourceId
      };

      this.panelBtns = [{ type: 'primary', label: 'Edit' }];

      if (response.deleted) {
        const tertiaryBtn = { type: 'tertiary', label: 'Restore' };
        this.panelBtns.unshift(tertiaryBtn);
      } else {
        const accentBtn = { type: 'accent', label: 'Archive' };
        this.panelBtns.unshift(accentBtn);
      }

      this.OpenDetailPanel();
    },
    handlePrimaryBtn(id: number) {
      this.$router.push(`/edit-purchase-order/${id}`);
      this.CloseDetailPanel();
    },
    async deletePO(id: number) {
      if (
        window.confirm('Are you sure you want to archive this purchase order?')
      ) {
        try {
          await PrintAPI.delete(`/deletePO/${id}`);
          this.ShowNotification({
            type: 'success',
            message: 'Purchase Order archived successfully!'
          });
          this.fetchPOs();
        } catch (error) {
          this.ShowNotification({
            type: 'error',
            message: 'Error archiving purchase order'
          });
          return error;
        }
      }
    },
    async restorePO(id: number) {
      try {
        await PrintAPI.put(`/recoverPO/${id}`);
        this.ShowNotification({
          type: 'success',
          message: 'Purchase Order restored successfully!'
        });
        this.fetchPOs();
      } catch (error) {
        this.ShowNotification({
          type: 'error',
          message: 'Error restoring purchase order'
        });
        return error;
      }
    }
  }
});
