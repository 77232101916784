
import { defineComponent } from 'vue';
export default defineComponent({
  name: 'Select',
  props: {
    label: { type: String, default: '' },
    sublabel: { type: String, default: null },
    name: { type: String, default: '' },
    required: { type: Boolean, default: false },
    readonly: { type: Boolean, default: false },
    modelValue: { type: String || Number || null, default: null }
  },
  emits: ['update:modelValue'],
  methods: {
    onSelect(event: Event) {
      const target = event.target as HTMLSelectElement;
      this.$emit('update:modelValue', target.value);
    }
  }
});
