
import { defineComponent } from 'vue';
import { mapActions, mapGetters } from 'vuex';
import { PrintAPI } from '@/services/PrintAPIClient';
import { exportCSVFile } from '@/services/utils-csv-export';
import {
  fetchTableInventory,
  getDetailPanelInventory
} from '@/services/inventory/inventory-data';
import { InventoryItem } from '@/services/inventory/types';
import { PanelBtn } from '@/store/types';

import PrimaryButton from '@/components/buttons/PrimaryButton.vue';
import SecondaryButton from '@/components/buttons/SecondaryButton.vue';
import Table from '@/components/tables/Table.vue';
import TableDetail from '@/components/tables/TableDetail.vue';
import SearchBar from '@/components/searches/SearchBar.vue';
import Loading from 'vue-loading-overlay';

export default defineComponent({
  name: 'Inventory',
  components: {
    PrimaryButton,
    SecondaryButton,
    Table,
    TableDetail,
    SearchBar,
    Loading
  },
  data() {
    return {
      isLoading: false,
      inventory: [] as InventoryItem[],
      inventoryProperties: [] as string[],
      inventoryTableHeadProperties: [] as string[],
      active: true,
      timer: null as number | null,
      debouncedSearchInput: '',
      tablekey: 0,
      panelData: {},
      panelBtns: [] as PanelBtn[],
      totalInvOnHand: 0,
      totalQuantity: 0
    };
  },
  computed: {
    ...mapGetters(['GetDetailPanelIsOpen']),
    filterInventory(): InventoryItem[] {
      let returnInventory = this.inventory;
      let filteredInventory = this.inventory;

      if (this.active) {
        filteredInventory = filteredInventory.filter(
          (item: InventoryItem) => item.deletedAt === null
        );
      } else {
        filteredInventory = filteredInventory.filter(
          (item: InventoryItem) => item.deletedAt != null
        );
      }

      returnInventory = filteredInventory;

      if (this.debouncedSearchInput != '' && this.debouncedSearchInput) {
        returnInventory = this.inventory.filter((item: InventoryItem) => {
          return this.debouncedSearchInput
            .toLowerCase()
            .split(' ')
            .every((v) => JSON.stringify(item).toLowerCase().includes(v));
        });
      }

      return returnInventory;
    },
    searchInput: {
      get(): string {
        return this.debouncedSearchInput;
      },
      set(val: string) {
        if (this.timer) clearTimeout(this.timer);
        this.timer = setTimeout(() => {
          this.debouncedSearchInput = val;
        }, 500);
      }
    }
  },
  created() {
    this.getInventory();
    this.getInventoryValueOnHand();
  },

  methods: {
    ...mapActions(['OpenDetailPanel', 'CloseDetailPanel', 'ShowNotification']),
    async getInventory() {
      try {
        this.isLoading = true;
        const response = await fetchTableInventory();
        this.inventory = response.inventory;
        this.inventoryProperties = response.inventoryProperties;
        this.inventoryTableHeadProperties =
          response.inventoryTableHeadProperties;
        this.isLoading = false;
      } catch (error) {
        return error;
      }
    },
    async openTableDetail(id: number) {
      try {
        const response = await getDetailPanelInventory(id);

        this.panelData = response.detailPanelData;

        this.panelBtns = [{ type: 'primary', label: 'Edit' }];

        if (response.deleted) {
          const tertiaryBtn = { type: 'tertiary', label: 'Restore' };
          this.panelBtns.unshift(tertiaryBtn);
        } else {
          const accentBtn = { type: 'accent', label: 'Archive' };
          this.panelBtns.unshift(accentBtn);
        }

        this.OpenDetailPanel();
      } catch (error) {
        return error;
      }
    },
    async getInventoryValueOnHand() {
      try {
        const res = await PrintAPI.get(`/getValuesOnHand`);
        this.totalInvOnHand = res.data[0].total.toFixed(2);
        this.totalQuantity = res.data[0].quantity;
      } catch (error) {
        return error;
      }
    },
    handlePrimaryBtn(id: number) {
      this.$router.push(`/edit-inventory/${id}`);
    },
    async deleteInventoryItem(id: number) {
      if (
        window.confirm('Are you sure you want to archive this inventory item?')
      ) {
        try {
          await PrintAPI.delete(`/deleteInventoryItem/${id}`);
          this.ShowNotification({
            type: 'success',
            message: 'Inventory item archived successfully!'
          });
          this.getInventory();
        } catch (error) {
          this.ShowNotification({
            type: 'error',
            message: 'Error archiving inventory item'
          });
          return error;
        }
      }
    },
    async recoverInventoryItem(id: number) {
      try {
        await PrintAPI.put(`/recoverInventoryItem/${id}`);
        this.ShowNotification({
          type: 'success',
          message: 'Inventory item restored successfully'
        });
        this.getInventory();
      } catch (error) {
        this.ShowNotification({
          type: 'error',
          message: 'Error restoring inventory item'
        });
        return error;
      }
    },
    download() {
      const headers = {
        name: 'Item Name',
        type: 'Item Type',
        quantity: 'Quantity',
        cost_per_unit: 'Item Cost Per Unit',
        total_value_on_hand: 'Total Value On Hand',
        notes: 'Notes'
      };

      const activeInventory = this.inventory.filter(
        (item: any) => item.deletedAt === null
      );

      const itemsNotFormatted = activeInventory;

      const itemsFormatted = [] as any[];

      //format the data
      itemsNotFormatted.forEach((item) => {
        itemsFormatted.push({
          name: item.name,
          type: item.typeName,
          quantity: item.quantity,
          cost_per_unit: item.itemCostPerUnit,
          total_value_on_hand: item.totalValue,
          notes: item.notes
            ? item.notes.replace(/,/g, '').replace(/\n/g, '')
            : ''
        });
      });
      itemsFormatted.push({
        name: 'Total Quantity: ',
        type: this.totalQuantity,
        quantity: 'Total Inventory Value: ',
        cost_per_unit: this.totalInvOnHand
      });
      const fileTitle = 'inventory-data'; //or 'my-unique-title'

      //call the exportCSVFile() function to process the JSON and trigger the download
      exportCSVFile(headers, itemsFormatted, fileTitle);
    }
  }
});
