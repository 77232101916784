
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'Input',
  props: {
    label: { type: String, default: '' },
    name: { type: String, default: '' },
    sublabel: { type: String, default: null },
    type: { type: String, default: 'text' },
    min: { type: Number, default: 0 },
    placeholderText: { type: String, default: '' },
    required: { type: Boolean, default: false },
    readonly: { type: Boolean, default: false },
    modelValue: { type: String || Number || null, default: null }
  },
  emits: ['update:modelValue'],
  methods: {
    onInput(event: Event) {
      const target = event.target as HTMLInputElement;
      this.$emit('update:modelValue', target.value);
    }
  }
});
