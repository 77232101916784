
import { defineComponent } from 'vue';
import { mapActions } from 'vuex';
import { PrintAPI } from '@/services/PrintAPIClient';

import Input from '@/components/forms/Input.vue';
import PrimaryButton from '@/components/buttons/PrimaryButton.vue';

export default defineComponent({
  name: 'ExternalPasswordReset',
  components: {
    Input,
    PrimaryButton
  },
  data() {
    return {
      password: '',
      passwordAssert: '',
      emailError: false
    };
  },
  methods: {
    ...mapActions(['ShowNotification']),
    async submitForm() {
      try {
        if (this.passwordAssert !== this.password) {
          this.ShowNotification({
            type: 'warning',
            message: 'Passwords must match'
          });
          return;
        }

        await PrintAPI.post(
          `password-reset/${this.$route.params.token}/${this.$route.params.id}`,
          { password: this.password }
        );
        this.ShowNotification({
          type: 'success',
          message: 'Password updated successfully!'
        });
        this.$router.push('/login');
      } catch (error: any) {
        this.ShowNotification({
          type: 'error',
          message: error.response.data.message
        });
      }
    }
  }
});
