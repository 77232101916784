
import { defineComponent } from 'vue';
export default defineComponent({
  name: 'SearchBar',
  props: {
    name: { type: String, default: '' },
    placeholderText: { type: String, default: '' },
    modelValue: { type: String, default: '' }
  },
  emits: ['update:modelValue'],
  methods: {
    onInput(event: Event) {
      const target = event.target as HTMLInputElement;
      this.$emit('update:modelValue', target.value);
    }
  }
});
