import { toDisplayString as _toDisplayString, createVNode as _createVNode, renderSlot as _renderSlot, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "flex items-center gap-4" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createBlock("div", null, [
    _createVNode("div", _hoisted_1, [
      _createVNode("label", {
        for: _ctx.name,
        class: "font-bold text-grey-darker block my-4"
      }, _toDisplayString(_ctx.label), 9, ["for"]),
      _createVNode("span", {
        for: _ctx.name,
        class: "text-sm text-gray-400"
      }, _toDisplayString(_ctx.sublabel ? `(${_ctx.sublabel})` : null), 9, ["for"])
    ]),
    _createVNode("select", {
      class: ["\n        block\n        w-full\n        bg-white\n        border border-grey-light\n        px-2\n        py-2.5\n        rounded\n        shadow\n      ", _ctx.readonly ? 'cursor-not-allowed' : ''],
      value: _ctx.modelValue,
      name: _ctx.name,
      required: _ctx.required,
      disabled: _ctx.readonly,
      onChange: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.onSelect && _ctx.onSelect(...args)))
    }, [
      _renderSlot(_ctx.$slots, "default")
    ], 42, ["value", "name", "required", "disabled"])
  ]))
}