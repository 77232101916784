
import { defineComponent } from 'vue';
import NotificationBar from '@/global/NotificationBar.vue';

export default defineComponent({
  name: 'LoginLayout',
  components: {
    NotificationBar
  }
});
